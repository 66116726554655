import { SvgIcon } from '@mui/material';
import { FC } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';

import { styles } from './Home.styles';

export interface IconProps extends WithStyles<typeof styles> {
  color?: string;
}

const HomeIconComponent: FC<IconProps> = ({ classes, color = '#7E8899' }) => {
  return (
    <SvgIcon
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      classes={{ root: classes.root }}
    >
      <path
        d="M9.81859 1.32421C9.52582 1.0965 9.37943 0.98264 9.21779 0.938875C9.07516 0.900258   8.92484 0.900258 8.78221 0.938875C8.62057 0.98264 8.47418 1.0965 8.18141 1.32421L2.52949 5.72014C2.15168 6.01399 1.96278 6.16092 1.82669 6.34492C1.70614 6.50791 1.61633 6.69153 1.56169 6.88675C1.5 7.10714 1.5 7.34645 1.5 7.82508V13.8542C1.5 14.7876 1.5 15.2543 1.68166 15.6108C1.84144 15.9244 2.09641 16.1794 2.41002 16.3392C2.76654 16.5209 3.23325 16.5209 4.16667 16.5209H5.83333C6.06669 16.5209 6.18337 16.5209 6.2725 16.4754C6.3509 16.4355 6.41464 16.3718 6.45459 16.2934C6.5 16.2042 6.5 16.0875 6.5 15.8542V10.3542C6.5 9.88748 6.5 9.65413 6.59083 9.47587C6.67072 9.31907 6.79821 9.19158 6.95501 9.11169C7.13327 9.02086 7.36662 9.02086 7.83333 9.02086H10.1667C10.6334 9.02086 10.8667 9.02086 11.045 9.11169C11.2018 9.19158 11.3293 9.31907 11.4092 9.47587C11.5 9.65413 11.5 9.88748 11.5 10.3542V15.8542C11.5 16.0875 11.5 16.2042 11.5454 16.2934C11.5854 16.3718 11.6491 16.4355 11.7275 16.4754C11.8166 16.5209 11.9333 16.5209 12.1667 16.5209H13.8333C14.7668 16.5209 15.2335 16.5209 15.59 16.3392C15.9036 16.1794 16.1586 15.9244 16.3183 15.6108C16.5 15.2543 16.5 14.7876 16.5 13.8542V7.82508C16.5 7.34645 16.5 7.10714 16.4383 6.88675C16.3837 6.69153 16.2939 6.50791 16.1733 6.34492C16.0372 6.16092 15.8483 6.014 15.4705 5.72015L9.81859 1.32421Z"
        stroke={color}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export const HomeIcon = withStyles(styles)(HomeIconComponent);
