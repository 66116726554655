import { SvgIcon } from '@mui/material';
import { FC } from 'react';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';

import { styles } from './Search.styles';

export interface SearchIconProps extends WithStyles<typeof styles> {
  color?: string;
  strokeWidth?: number;
}

const SearchIconComponent: FC<SearchIconProps> = ({ classes, color = '#828D9E', strokeWidth = 1.67 }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="transparent"
      classes={{ root: classes.root }}
    >
      <path
        d="M13 13L10.1 10.1M11.6667 6.33333C11.6667 9.27885 9.27885 11.6667 6.33333 11.6667C3.38781 11.6667 1 9.27885 1 6.33333C1 3.38781 3.38781 1 6.33333 1C9.27885 1 11.6667 3.38781 11.6667 6.33333Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export const SearchIcon = withStyles(styles)(SearchIconComponent);
