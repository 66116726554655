import { SvgIcon } from '@mui/material';
import { FC } from 'react';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';

import { styles } from './DotsVertical.styles';

export interface DownArrowIconProps extends WithStyles<typeof styles> {
  color?: string;
}

const DotsVerticalIconComponent: FC<DownArrowIconProps> = ({ classes, color = '#7E8899' }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      classes={{ root: classes.root }}
    >
      <path
        d="M10 10.8333C10.4602 10.8333 10.8333 10.4602 10.8333 9.99998C10.8333 9.53974 10.4602 9.16665 10 9.16665C9.53977 9.16665 9.16667 9.53974 9.16667 9.99998C9.16667 10.4602 9.53977 10.8333 10 10.8333Z"
        stroke={color}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 4.99998C10.4602 4.99998 10.8333 4.62688 10.8333 4.16665C10.8333 3.70641 10.4602 3.33331 10 3.33331C9.53977 3.33331 9.16667 3.70641 9.16667 4.16665C9.16667 4.62688 9.53977 4.99998 10 4.99998Z"
        stroke={color}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 16.6666C10.4602 16.6666 10.8333 16.2935 10.8333 15.8333C10.8333 15.3731 10.4602 15 10 15C9.53977 15 9.16667 15.3731 9.16667 15.8333C9.16667 16.2935 9.53977 16.6666 10 16.6666Z"
        stroke={color}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export const DotsVerticalIcon = withStyles(styles)(DotsVerticalIconComponent);
