import { TransitionsOptions } from '@mui/material/styles';

export const initializeTransitions = (config?: TransitionsOptions): TransitionsOptions => {
  return {
    duration: {
      standard: 0.4,
    },
    ...config,
  };
};
