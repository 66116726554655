import {
  FormControl,
  FormLabel,
  MenuItem,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  TooltipProps,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';
import cx from 'classnames';
import { ChangeEvent, FC, ReactNode, useMemo } from 'react';

import { Ellipsis } from '@shared/components/ellipsis';
import { FieldHelperText } from '@shared/components/field-helper-text';
import { Tooltip } from '@shared/components/tooltip';

import { styles } from './SingleSelect.styles';
import { Option, SingleSelectOnChange, Value } from '../Select.types';

type Classes = WithStyles<typeof styles>;

export interface SingleSelectProps extends Omit<MuiSelectProps, 'classes' | 'onChange' | 'label'>, Classes {
  label?: ReactNode;
  border?: boolean;
  errorText?: string;
  fullWidth?: boolean;
  infoText?: string;
  options: Array<Option>;
  placeholder?: string;
  tooltip?: string;
  withTooltipValue?: boolean;
  tooltipPlacement?: TooltipProps['placement'];
  value?: Value;
  onChange?: SingleSelectOnChange;
  renderCustomOption?: (option: Option) => ReactNode;
}

const SingleSelectComponent: FC<SingleSelectProps> = ({
  border = true,
  label,
  classes,
  errorText,
  fullWidth = false,
  infoText = '',
  options,
  placeholder,
  renderValue,
  tooltip,
  withTooltipValue,
  tooltipPlacement = 'left',
  value,
  onChange,
  renderCustomOption,
  ...otherProps
}) => {
  const placeholderVisible = useMemo(() => !value, [value]);

  const handleChange = (e: ChangeEvent<any>) => {
    e.persist();

    const { value } = e.target;

    if (onChange && value) {
      const option = getOption(value);

      onChange(e, value, option);
    }

    // When user choose empty option ("Select option")
    if (onChange && !value) {
      onChange(e, '');
    }
  };

  const handleClose = (e: ChangeEvent<any>) => {
    if (otherProps?.onClose) otherProps.onClose(e);
    setTimeout(() => {
      // @ts-ignore
      document?.activeElement?.blur();
    }, 0);
  };

  const getOption = (id: unknown) => {
    return options.find((option) => option.id == id) as Option;
  };

  const renderSelectorValue = (v: unknown) => {
    if (renderValue && v) {
      return renderValue(v);
    }

    if (placeholderVisible) {
      return (
        <Ellipsis
          withTooltip={false}
          maxWidth="100%"
          text={placeholder}
          classes={{
            root: cx(classes.placeholder, { [classes.placeholderDisabled]: otherProps.disabled }),
          }}
        />
      );
    }

    return (
      <div
        className={classes.value}
        dangerouslySetInnerHTML={{ __html: (getOption(v)?.label || placeholder) as string }}
      />
    );
  };

  const renderOption = (option: Option) => {
    if (renderCustomOption) {
      return renderCustomOption(option);
    }

    if (option.disabled) {
      return (
        <Tooltip data-value={option.id} placement="left" key={option.id} title={option.tooltip as string}>
          <MenuItem
            value={option.id}
            classes={{
              root: cx(classes.menuItem, classes.menuItemDisabled),
              selected: classes.menuItemSelected,
            }}
          >
            <span dangerouslySetInnerHTML={{ __html: option.label }} />
          </MenuItem>
        </Tooltip>
      );
    }

    return (
      <MenuItem
        value={option.id}
        key={option.id}
        classes={{
          root: classes.menuItem,
          selected: classes.menuItemSelected,
        }}
      >
        <span dangerouslySetInnerHTML={{ __html: option.label }} />
      </MenuItem>
    );
  };

  const tooltipValue = useMemo(() => {
    if (!withTooltipValue) return '';

    return Array.isArray(value) ? value.map((id) => getOption(id)?.label).join(', ') : getOption(value)?.label;
  }, [withTooltipValue, value, options]);

  let content = (
    <>
      {label && (
        <FormLabel classes={{ root: classes.labelRoot }} component="legend" error={otherProps.error}>
          {label}
        </FormLabel>
      )}
      <MuiSelect
        variant="standard"
        {...otherProps}
        displayEmpty
        defaultValue={placeholder}
        value={value}
        onChange={handleChange}
        className={cx(classes.select, { [classes.selectDisabled]: otherProps.disabled })}
        classes={{
          root: classes.muiSelectRoot,
          select: classes.muiSelect,
          icon: cx(classes.muiIcon, { [classes.iconPlaceholder]: placeholderVisible }),
          outlined: classes.muiOutlined,
        }}
        error={otherProps.error}
        renderValue={renderSelectorValue}
        IconComponent={KeyboardArrowDownIcon}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          }
        }}
        onClose={handleClose}>
        {options.map((option) => renderOption(option))}
      </MuiSelect>
      <FieldHelperText
        classes={{ root: classes.helperText }}
        error={!!otherProps.error}
        errorText={errorText}
        infoText={infoText}
      />
    </>
  );
  if (tooltip || withTooltipValue) {
    content = (
      <Tooltip placement={tooltipPlacement} title={tooltip || tooltipValue}>
        {content}
      </Tooltip>
    );
  }

  return (
    <FormControl
      size="small"
      variant="outlined"
      classes={{ root: cx(classes.root, { [classes.rootNoBorder]: !border, [classes.rootFullWidth]: fullWidth }) }}
      error={otherProps.error}
    >
      {content}
    </FormControl>
  );
};

export const SingleSelect = withStyles(styles)(SingleSelectComponent);
