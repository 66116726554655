import { Components } from '@mui/material/styles';

export const initializeDrawerComponents = (
  config?: Components['MuiDrawer']
): Components['MuiDrawer'] => {
  return {
    styleOverrides: {
      root: {},
      paper: {
        width: 570,

      '@media (max-width: 600px)': {
          width: '100%',
        },
      },
    },
    ...config,
  };
};

