import { alpha, Components } from '@mui/material/styles';

import { colors } from '@shared/theme/colours';

export const initializeRadioComponents = (
  config?: Components['MuiRadio']
): Components['MuiRadio'] => {
  return {
    styleOverrides: {
      root: {},
      colorSecondary: {
      color: colors.black.darkest,

      '&.Mui-checked': {
        color: colors.black.darkest,
      },

      '&.Mui-disabled': {
        color: alpha(colors.black.darkest, 0.4),
      },
    },
    ...config,
    },
  };
};
