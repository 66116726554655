import { Components } from '@mui/material/styles';

export const initializeAccordionComponents = (
  config?: Components['MuiAccordion']
): Components['MuiAccordion'] => {
  return {
    styleOverrides: {
      root: {},
    },
    ...config,
  };
};
