import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {},
    errorHandler: {
      minHeight: '100vh',
      padding: spacing(0, 8),
    },
  });
