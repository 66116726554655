import { SvgIcon } from '@mui/material';
import { FC } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';

import { styles } from './CheckCircleBroken.styles';

export interface BuildingIconProps extends WithStyles<typeof styles> {
  color?: string;
}

const CheckCircleBrokenIconComponent: FC<BuildingIconProps> = ({ classes, color = '#515D70' }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      classes={{ root: classes.root }}
    >
      <g clipPath="url(#clip0_918_885)">
        <path
          d="M18.3333 9.25894V10.0256C18.3323 11.8226 17.7504 13.5712 16.6744 15.0105C15.5984 16.4497 14.086 17.5027 12.3628 18.0122C10.6395 18.5217 8.79768 18.4605 7.11202 17.8378C5.42636 17.215 3.98717 16.064 3.00909 14.5565C2.03101 13.049 1.56645 11.2657 1.68469 9.47253C1.80293 7.67941 2.49763 5.97254 3.66519 4.6065C4.83275 3.24045 6.41061 2.28842 8.16345 1.89239C9.91629 1.49636 11.7502 1.67755 13.3916 2.40894M18.3333 3.35417L9.99996 11.6958L7.49996 9.19583"
          stroke={color}
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_918_885">
          <rect width="20" height="20" fill="white" transform="translate(0 0.020874)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export const CheckCircleBrokenIcon = withStyles(styles)(CheckCircleBrokenIconComponent);
