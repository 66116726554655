import { Link as MuiLink } from '@mui/material';
import cx from 'classnames';
import { FC, HTMLAttributeAnchorTarget, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { useAppUrl } from '@shared/contexts/ConfigContext';
import { getTransformedRoute } from '@shared/utils/route';

import { styles } from './Link.styles';

export interface LinkProps extends WithStyles<typeof styles> {
  target?: HTMLAttributeAnchorTarget;
  to: string;
}

const LinkComponent: FC<LinkProps> = ({ classes, children, target, to: suppliedTo }) => {
  const { appUrl } = useAppUrl();

  const to = useMemo(() => getTransformedRoute(appUrl, suppliedTo), [appUrl, suppliedTo]);

  if (to.startsWith('http') || target) {
    return (
      <MuiLink
        classes={{ root: cx(classes.root, classes.rootExternal) }}
        href={to}
        target={target}
        underline="none">
        {children}
      </MuiLink>
    );
  }

  return (
    <RouterLink className={cx(classes.root, classes.rootInternal)} to={to}>
      {children}
    </RouterLink>
  );
};

export const Link = withStyles(styles)(LinkComponent);
