import { Collapse } from '@mui/material';
import cx from 'classnames';
import { FC, ReactNode, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Badge, BADGE_SIZE, BadgeProps } from '@shared/components/badge';
import { Flex } from '@shared/components/flex';
import { Link } from '@shared/components/link';
import { ChevronDownIcon } from '@shared/icons/chevron-down';
import { ZapIcon } from '@shared/icons/zap';
import { checkIsActive } from '@shared/utils/common';

import { styles } from './SideNavigationGroup.styles';

export interface SideNavigationGroupItem {
  badge?: Omit<BadgeProps, 'classes'>;
  to: string;
  title: string;
}

export interface SideNavigationGroupProps extends WithStyles<typeof styles> {
  badge?: Omit<BadgeProps, 'classes'>;
  expanded?: boolean;
  fixed?: boolean;
  icon: ReactNode;
  title: string;
  items: SideNavigationGroupItem[];
  setExpanded?: UseStateSetter<boolean>;
}

const SideNavigationGroupComponent: FC<SideNavigationGroupProps> = ({
  badge,
  classes,
  expanded,
  fixed,
  icon,
  items,
  title,
  setExpanded,
}) => {
  const { pathname } = useLocation();

  const renderedItems = useMemo(
    () =>
      items.map(({ to, title, badge }, index) => (
        <Link
          key={index}
          classes={{ root: classes.link, rootInternal: cx({ [classes.linkActive]: checkIsActive(pathname, to) }) }}
          to={to}
        >
          <div className={classes.linkDotContainer}>
            <div className={classes.linkDot} />
          </div>
          <span className={classes.linkText}>{title}</span>
          {!!badge && (
            <Badge
              size={BADGE_SIZE.small}
              startIcon={<ZapIcon classes={{ root: classes.badgeIcon }} />}
              {...badge}
              classes={{ badge: classes.linkBadge }}
            />
          )}
        </Link>
      )),
    [classes, items, pathname]
  );

  return (
    <div className={classes.root}>
      <Flex
        alignItems="center"
        className={cx(classes.toggleButton, { [classes.toggleButtonActive]: fixed })}
        wrap="nowrap"
        onClick={setExpanded ? () => setExpanded((_prev) => !_prev) : undefined}
      >
        <span className={classes.toggleStartIcon}>{icon}</span>
        <span className={classes.toggleText}>{title}</span>
        {!!badge && (
          <Badge
            size={BADGE_SIZE.small}
            startIcon={<ZapIcon classes={{ root: classes.badgeIcon }} />}
            {...badge}
            classes={{ badge: classes.linkBadge }}
          />
        )}
        {!fixed && (
          <ChevronDownIcon classes={{ root: cx(classes.toggleIcon, { [classes.toggleIconCollapsed]: expanded }) }} />
        )}
      </Flex>
      <Collapse in={fixed ? true : expanded} timeout={400} unmountOnExit>
        <Flex direction="column">{renderedItems}</Flex>
      </Collapse>
    </div>
  );
};

export const SideNavigationGroup = withStyles(styles)(SideNavigationGroupComponent);
