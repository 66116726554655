import { Components } from '@mui/material/styles';

import { colors } from '@shared/theme/colours';

export const initializeInputBaseComponents = (
  config?: Components['MuiInputBase']
): Components['MuiInputBase'] => {
  return {
    styleOverrides: {
      root: {
        '& $disabled': {
          color: colors.black.darkest,
        },
    },
    inputMultiline: {
        resize: 'vertical',
      },
    },
    ...config,
  };
};
