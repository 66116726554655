import { Components } from '@mui/material/styles';

import { colors } from '@shared/theme/colours';

export const initializeTabComponents = (
  config?: Components['MuiTab']
): Components['MuiTab'] => {
  return {
    styleOverrides: {
      root: {
        minWidth: 'auto',

      '@media (min-width: 600px)': {
        minWidth: 'auto',
      },
    },
    textColorInherit: {
      textTransform: 'none',
      color: colors.grey.main,
      fontWeight: 400,

      '&:hover': {
        color: colors.black.darkest,
      },

      '&$selected': {
          color: colors.black.darkest,
        },
      },
    },
    ...config,
  };
};
