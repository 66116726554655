import { SvgIcon } from '@mui/material';
import { FC } from 'react';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';

import { styles } from './Alert.styles';

interface AlertIconProps extends WithStyles<typeof styles> {
  color?: string;
}

const AlertIconComponent: FC<AlertIconProps> = ({ classes, color = '#B42318' }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="27"
      viewBox="0 0 30 27"
      fill="none"
      classes={{ root: classes.root }}
    >
      <path
        d="M14.9998 10.0003V15.3336M14.9998 20.667H15.0131M13.1535 3.18927L2.187 22.1314C1.57872 23.1821 1.27459 23.7074 1.31954 24.1386C1.35875 24.5146 1.55577 24.8564 1.86158 25.0787C2.21219 25.3336 2.81921 25.3336 4.03324 25.3336H25.9663C27.1803 25.3336 27.7873 25.3336 28.1379 25.0787C28.4437 24.8564 28.6408 24.5146 28.68 24.1386C28.7249 23.7074 28.4208 23.1821 27.8125 22.1314L16.846 3.18927C16.2399 2.14238 15.9369 1.61894 15.5415 1.44314C15.1966 1.28979 14.8029 1.28979 14.458 1.44314C14.0627 1.61894 13.7596 2.14239 13.1535 3.18927Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export const AlertIcon = withStyles(styles)(AlertIconComponent);
