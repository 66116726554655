import createStyles from '@mui/styles/createStyles';

export const styles = () =>
  createStyles({
    root: {
      lineHeight: 0,
      width: 'inherit',
    },
    image: {
      width: 'inherit',
    },
  });
