import { ButtonProps } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import cx from 'classnames';
import { FC, ReactNode } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Button } from '@shared/components/button';
import { Flex } from '@shared/components/flex';

import { HeaderTheme, styles } from './DrawerHeader.styles';

export interface DrawerHeaderProps extends WithStyles<typeof styles> {
  customAction?: ReactNode;
  header?: ReactNode;
  theme?: HeaderTheme;
  withClose?: boolean;
  onClose: () => void;
}

const closeButtonPropsConfig: Record<HeaderTheme, Pick<ButtonProps, 'color' | 'variant'>> = {
  [HeaderTheme.default]: {
    color: 'inherit',
    variant: 'outlined',
  },
  [HeaderTheme.primary]: {
    color: 'primary',
    variant: 'contained',
  },
};

const DrawerHeaderComponent: FC<DrawerHeaderProps> = ({
  classes,
  customAction,
  header,
  theme = HeaderTheme.default,
  withClose = true,
  onClose,
}) => {
  const justifyContent = header ? 'space-between' : 'flex-end';

  return (
    <Flex
      autoWidth={false}
      alignItems="center"
      justifyContent="space-between"
      className={cx(classes.header, classes[theme], { [classes.withBorder]: header })}
    >
      <Flex
        autoWidth={false}
        wrap="nowrap"
        justifyContent={justifyContent}
        alignItems="center"
        classes={{ root: classes.headerContainer }}
      >
        <div className={classes.headerText}>{header}</div>
        <Flex classes={{ root: classes.headerActionsContainer }} wrap="nowrap">
          {customAction}
          {withClose && (
            <Button
              size="small"
              text={<CloseIcon className={classes.headerIcon} />}
              onClick={onClose}
              classes={{
                root: classes.headerBtn,
                outlinedSizeSmall: classes.headerBtnSmall,
              }}
              {...closeButtonPropsConfig[theme]}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export const DrawerHeader = withStyles(styles)(DrawerHeaderComponent);
