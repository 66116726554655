import { Components } from '@mui/material/styles';

import { colors } from '@shared/theme/colours';
import { fontFamily } from '@shared/theme/units/typography';

import { initializeAccordionComponents } from './accordion';
import { initializeAccordionDetailsComponents } from './accordion-details';
import { initializeAccordionSummaryComponents } from './accordion-summary';
import { initializeBackdropComponents } from './backdrop';
import { initializeButtonComponents } from './button';
import { initializeCheckboxComponents } from './checkbox';
import { initializeChipComponents } from './chip';
import { initializeDialogComponents } from './dialog';
import { initializeDrawerComponents } from './drawer';
import { initializeFormControlLabelComponents } from './form-control-label';
import { initializeIconButtonComponents } from './icon-button';
import { initializeInputComponents } from './input';
import { initializeInputBaseComponents } from './input-base';
import { initializeLinkComponents } from './link';
import { initializeListComponents } from './list';
import { initializeListItemComponents } from './list-item';
import { initializeMenuComponents } from './menu';
import { initializeOutlinedInputComponents } from './outlined-input';
import { initializePaperComponents } from './paper';
import { initializeRadioComponents } from './radio';
import { initializeTabComponents } from './tab';
import { initializeTableCellComponents } from './table-cell';
import { initializeTabsComponents } from './tabs';
import { initializeTooltipComponents } from './tooltip';

export const initializeOverrides = (config?: Components): Components => {
  return {
    MuiCssBaseline: {
      styleOverrides: {
        '@font-face': [
          {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: '100 900',
            fontDisplay: 'swap',
            src: `url('/fonts/inter/Inter-roman.var.woff2?v=3.19') format('woff2')`,
          },
          {
            fontFamily: 'Inter',
            fontStyle: 'italic',
            fontWeight: '100 900',
            fontDisplay: 'swap',
            src: `url('/fonts/inter/Inter-italic.var.woff2?v=3.19') format('woff2')`,
          },
          {
            fontFamily: 'CabinetGrotesk-Variable',
            fontStyle: 'normal',
            fontWeight: '100 900',
            fontDisplay: 'swap',
            src: `url('/fonts/cabinetgrotesk/CabinetGrotesk-Variable.woff2') format('woff2')`,
          },
        ],
        html: {
          WebkitFontSmoothing: 'antialiased',
          MozOsxFontSmoothing: 'grayscale',
          height: '100%',
        },
        body: {
          fontFamily: 'Inter, sans-serif',
          fontWeight: 400,
          lineHeight: 1.43,
          backgroundColor: '#FFFFFF',
          WebkitFontSmoothing: 'antialiased',
          MozOsxFontSmoothing: 'grayscale',
        },
        h1: {
          fontFamily: 'CabinetGrotesk-Variable, sans-serif',
          fontWeight: 800,
        },
        root: {
          body: {
            backgroundColor: '#FFFFFF',
          },
          '#root': {
            height: '100%',
          },
          a: {
            cursor: 'pointer',
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'none',
            },
          },
          input: {
            '&::-ms-clear, &::-ms-reveal': {
              display: 'none',
            },
            '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
              '-webkit-appearance': 'none',
            },
          },
          span: {
            fontWeight: 400,
            fontFamily: fontFamily,
          },
          h3: {
            fontSize: 16,
            color: colors.black.darker,
          },
          '.MuiFormHelperText-root': {
            display: 'none',
          },
          '.icon': {
            blockSize: '24px',
            '&::before': {
              fontFamily: 'ae-marketplace !important',
              fontStyle: 'normal !important',
              fontWeight: 'normal !important',
              fontVariant: 'normal !important',
              textTransform: 'none !important',
              speak: 'none',
              lineHeight: 1,
              '-webkit-font-smoothing': 'antialiased',
              '-moz-osx-font-smoothing': 'grayscale',
            },
          },
          '.iconlg': {
            blockSize: '63px',
            '&::before': {
              fontFamily: 'as-large-icons !important',
              fontStyle: 'normal !important',
              fontWeight: 'normal !important',
              fontVariant: 'normal !important',
              textTransform: 'none !important',
              speak: 'none',
              lineHeight: 1,
              '-webkit-font-smoothing': 'antialiased',
              '-moz-osx-font-smoothing': 'grayscale',
            },
          },
          '.MuiLink-root': {
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'none',
            },
          },
        },
      },
    },
    MuiAccordion: initializeAccordionComponents(config?.MuiAccordion),
    MuiAccordionSummary: initializeAccordionSummaryComponents(config?.MuiAccordionSummary),
    MuiAccordionDetails: initializeAccordionDetailsComponents(config?.MuiAccordionDetails),
    MuiChip: initializeChipComponents(config?.MuiChip),
    MuiTab: initializeTabComponents(config?.MuiTab),
    MuiTabs: initializeTabsComponents(config?.MuiTabs),
    MuiButton: initializeButtonComponents(config?.MuiButton),
    MuiDrawer: initializeDrawerComponents(config?.MuiDrawer),
    MuiMenu: initializeMenuComponents(config?.MuiMenu),
    MuiLink: initializeLinkComponents(config?.MuiLink),
    MuiOutlinedInput: initializeOutlinedInputComponents(config?.MuiOutlinedInput),
    MuiInputBase: initializeInputBaseComponents(config?.MuiInputBase),
    MuiTooltip: initializeTooltipComponents(config?.MuiTooltip),
    MuiList: initializeListComponents(config?.MuiList),
    MuiListItem: initializeListItemComponents(config?.MuiListItem),
    MuiPaper: initializePaperComponents(config?.MuiPaper),
    MuiTableCell: initializeTableCellComponents(config?.MuiTableCell),
    MuiDialog: initializeDialogComponents(config?.MuiDialog),
    MuiRadio: initializeRadioComponents(config?.MuiRadio),
    MuiCheckbox: initializeCheckboxComponents(config?.MuiCheckbox),
    MuiInput: initializeInputComponents(config?.MuiInput),
    MuiBackdrop: initializeBackdropComponents(config?.MuiBackdrop),
    MuiFormControlLabel: initializeFormControlLabelComponents(config?.MuiFormControlLabel),
    MuiIconButton: initializeIconButtonComponents(config?.MuiIconButton),
    ...config,
  };
};
