import { alpha, Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

import { colors } from '@shared/theme/colours';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'nowrap',
    },
    icon: {
      height: 31,
      width: 35,
      marginRight: spacing(2),
      cursor: 'pointer',
    },
    textIcon: {
      cursor: 'pointer',
      height: 16,
      marginBottom: 3,
      marginTop: 1,
      width: 122,
    },
    poweredBy: {
      color: alpha(colors.white.lightest, 0.6),
      fontSize: 10,
      fontWeight: 500,
      lineHeight: 1,
      userSelect: 'none',
    },
    poweredByLink: {
      color: colors.white.lightest,
      paddingLeft: spacing(1),
    },
  });
