import { alpha, Components } from '@mui/material/styles';

import { colors } from '@shared/theme/colours';

export const initializeCheckboxComponents = (
  config?: Components['MuiCheckbox']
): Components['MuiCheckbox'] => {
  return {
    styleOverrides: {
      root: {},
      colorPrimary: {
        padding: '0 !important',
        marginRight: 8,

      '&:hover': {
        backgroundColor: 'transparent',
      },

      '&.Mui-checked': {
        '&:hover': {
          backgroundColor: 'transparent !important',
        },
      },
    },
    colorSecondary: {
      color: colors.black.darkest,

      '&.Mui-checked': {
        color: colors.black.darkest,
      },

      '&.Mui-disabled': {
          color: alpha(colors.black.darkest, 0.4),
        },
      },
    },
    ...config,
  };
};
