import createStyles from '@mui/styles/createStyles';

export const styles = () =>
  createStyles({
    root: {
      width: 102,
    },
    select: {},
    container: {},
  });
