const advisoryManage = {
  details: '/details',
  fees: '/fees',
  media: '/media',
} as const;

const disposalManageDataRooms = {
  root: '/data-room',
  documents: '/documents',
  marketing: '/marketing',
  publishing: '/publishing',
  users: '/users',
  insights: '/insights',
} as const;

const disposalsManage = {
  availability: '/availability',
  data_room: disposalManageDataRooms,
  dev_pipeline: '/dev-pipeline',
  fees: '/fees',
  find_matches: '/find-matches',
  // TODO: Refactor insights to be top-level key
  insights: {
    root: '/insights',
    overview: '/overview',
    marketplace_performance: '/marketplace-performance',
  },
  investment: '/investment',
  management: '/management',
  marketing: '/marketing',
  media: '/media',
  reporting: '/reporting',
  summary: '/summary',
  transactions: '/transactions',
} as const;

const leasesManage = {
  rootPath: '/leases/transactions/:id/manage',
  root: (transactionId: string) => ROUTES.organisation.leases_manage.rootPath.replace(':id', transactionId),

  details: '/details',
  lease_events: '/lease-events',
  management: '/management',
  summary: '/summary',
} as const;

const requirementsManage = {
  fees: '/fees',
  find_matches: '/search',
  investment_criteria: '/investment-criteria',
  management: '/management',
  matches: '/matches',
  summary: '/summary',
  transactions: '/transactions',
} as const;

const salesManage = {
  rootPath: '/sales/transactions/:id/manage',
  root: (transactionId: string) => ROUTES.organisation.sales_manage.rootPath.replace(':id', transactionId),

  summary: '/summary',
} as const;

const ROUTES = {
  initial: '/',
  logOut: '/logout',
  defaultRedirect: '/redirect',

  organisation: {
    advisory: {
      root: '/advisory',
      manage: advisoryManage,
    },
    contacts: '/customer-relations',
    dashboard: '/dashboard',
    diary: '/diary',
    team: {
      root: '/team',
      members: '/members',
    },

    campaigns: {
      root: '/campaigns',
      single_property: '/single-property',
      multi_property: '/multi-property',
      creating: '/creating',
    },

    crm: {
      root: '/crm',
      contacts: '/contacts',
      companies: '/companies',
    },

    dataManagement: {
      root: '/data-management',
      onboarding: '/onboarding',
      data_imports: '/data-imports',
    },

    disposals: {
      root: '/disposals',
      listDefault: '/disposals/all',
      list: {
        all: '/all',
        investment: '/investment',
        instruction_availability: '/instruction-availability',
        market_availability: '/market-availability',
        dev_pipeline: '/dev-pipeline',
        pitching: '/pitching',
      },
      manage: disposalsManage,
    },

    insights: {
      root: '/insights',
      requirements: '/requirements',
      viewings_old: '/viewings-old',
      viewings: '/viewings',
      sale_transactions: '/sale-transactions',
      letting_transactions: '/letting-transactions',
      disposals: '/disposals',
    },

    properties: {
      root: '/properties',
    },

    requirements: {
      root: '/requirements',
      list: {
        investment_vehicle: 'investment-vehicle',
      },
      manage: requirementsManage,
    },

    settings: {
      root: '/settings',
      features: '/features',
      password: '/password',
      profile: '/profile',
      campaign_emails: '/campaign-emails',
    },

    viewings: '/viewings',

    wipFees: {
      root: '/work-in-progress',
    },

    leases: {
      root: '/leases',
      radius_rankings: '/radius-rankings',
      transactions: '/transactions',
      lease_events: '/lease-events',
    },
    leases_manage: leasesManage,

    sales: {
      root: '/sales',
      investment: '/investment',
      occupational: '/occupational',
      radius_rankings: '/radius-rankings',
      transactions: '/transactions',
    },
    sales_manage: salesManage,
  },

  marketplace: {
    root: '/society',
    dashboard: '/dashboard',
    lettings: '/lettings',
    acquisitions: '/acquisitions',
    disposals: '/society/disposals',
    members: '/members',
    events: '/events',
    preferences: '/preferences',

    insights: {
      root: '/society/insights',
      acquisitions: '/acquisitions',
      disposals: '/disposals',
    },
  },

  styleGuide: {
    root: '/style-guide',
    alerts: '/alerts',
    badges: '/badges',
    buttons: '/buttons',
    dropdowns: '/dropdowns',
    forms: '/forms',
    icons: '/icons',
    iconsMigration: '/icons-migration',
    layout: '/layout',
    modals: '/modals',
    reports: '/reports',
    skeletonPages: '/skeleton-pages',
    tables: '/tables',
    uploadFiles: '/upload-files',
  },

  upgradeToPro: {
    root: '/upgrade-to-pro',
  },
};

export default ROUTES;
