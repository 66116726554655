import { alpha, Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

import { colors } from '@shared/theme/colours';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
      width: 95,
    },
    logo: {
      cursor: 'pointer',
    },
    poweredBy: {
      color: alpha(colors.white.lightest, 0.6),
      fontSize: 10,
      fontWeight: 500,
      lineHeight: 1,
      userSelect: 'none',
    },
    poweredByLink: {
      color: colors.white.lightest,
      paddingLeft: spacing(1),
    },
  });
