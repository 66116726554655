import { Link, useTheme } from '@mui/material';
import { FC } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { KatoLogoIcon } from '@shared/icons/kato-logo';

import { styles } from './KatoNavigationLogo.styles';

export interface KatoNavigationLogoProps extends WithStyles<typeof styles> {
  url: string;
}

const KatoNavigationLogoComponent: FC<KatoNavigationLogoProps> = ({ classes, url }) => {
  const theme = useTheme();

  return (
    (<Link classes={{ root: classes.root }} href={url} underline="hover">
      <KatoLogoIcon classes={{ root: classes.logo }} color={theme.palette.primarySpecial.main} />
    </Link>)
  );
};

export const KatoNavigationLogo = withStyles(styles)(KatoNavigationLogoComponent);
