import { Components } from '@mui/material/styles';

export const initializeListComponents = (config?: Components['MuiList']): Components['MuiList'] => {
  return {
    styleOverrides: {
      root: {},
      padding: {},
    },
    ...config,
  };
};