import { SvgIcon } from '@mui/material';
import { FC } from 'react';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';

import { styles } from './AlertRound.styles';

interface Props extends WithStyles<typeof styles> {
  color?: string;
}

const AlertRoundIconComponent: FC<Props> = ({ classes, color = '#D92D20' }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      classes={{ root: classes.root }}
    >
      <path
        d="M15 9.66675V15.0001M15 20.3334H15.0133M28.3333 15.0001C28.3333 22.3639 22.3638 28.3334 15 28.3334C7.63616 28.3334 1.66663 22.3639 1.66663 15.0001C1.66663 7.63628 7.63616 1.66675 15 1.66675C22.3638 1.66675 28.3333 7.63628 28.3333 15.0001Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export const AlertRoundIcon = withStyles(styles)(AlertRoundIconComponent);
