import createStyles from '@mui/styles/createStyles';

import { colors } from '@shared/theme/colours';

export const styles = () =>
  createStyles({
    root: {
      width: 20,
      minWidth: 20,
      height: 20,
      lineHeight: '18px',
      borderRadius: '50%',
      backgroundColor: colors.kato.salmon[100],
    },
    rootZero: {
      backgroundColor: colors.kato.salmon[100],
      border: `1px solid ${colors.kato.salmon[900]}`,
    },
    count: {
      fontSize: 14,
      color: colors.kato.salmon[900],
      lineHeight: 'normal',
    },
    countZero: {
      color: colors.grey.dark,
    },
  });
