import { Components } from '@mui/material/styles';

export const initializeAccordionDetailsComponents = (
  config?: Components['MuiAccordionDetails']
): Components['MuiAccordionDetails'] => {
  return {
    styleOverrides: {
      root: {},
    },
    ...config,
  };
};
