import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { FC } from 'react';

import { styles } from './MultiGroupSelect.styles';
import { GroupSelect, GroupSelectProps, MultiGroupSelectOnChange } from '../GroupSelect';

export interface MultiGroupSelectProps
  extends Omit<GroupSelectProps, 'classes' | 'defaultValue' | 'multiple' | 'onChange' | 'value'>,
    WithStyles<typeof styles> {
  defaultValue?: Id[];
  value: Id[];
  onChange?: MultiGroupSelectOnChange;
}

const MultiGroupSelectComponent: FC<MultiGroupSelectProps> = ({ onChange, ...groupSelectProps }) => {
  return (
    <GroupSelect
      {...groupSelectProps}
      multiple
      onChange={(value, option) => {
        if (onChange) {
          onChange(value as Id[], option);
        }
      }}
    />
  );
};

export const MultiGroupSelect = withStyles(styles)(MultiGroupSelectComponent);
