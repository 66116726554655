import { SvgIcon } from '@mui/material';
import { FC } from 'react';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';

import { styles } from './ChevronLeft.styles';

export type ChevronLeftProps = WithStyles<typeof styles>;

const ChevronLeftIconComponent: FC<ChevronLeftProps> = ({ classes }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      classes={{ root: classes.root }}
    >
      <path d="M5 9L1 5L5 1" stroke="#828D9E" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
};

export const ChevronLeftIcon = withStyles(styles)(ChevronLeftIconComponent);
