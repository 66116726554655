import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {},
    content: {},
    message: {
      color: '#515D70', //TODO replace after creating new palette
      fontWeight: 400,
    },
    progressWrapper: {
      width: '100%',
      marginTop: spacing(3),
    },
    progress: {
      borderRadius: 6,
    },
  });
