import { SvgIcon } from '@mui/material';
import { FC } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';

import { styles } from './User.styles';

export interface IconProps extends WithStyles<typeof styles> {
  color?: string;
}

const UserCheckIconComponent: FC<IconProps> = ({ classes, color = '#7E8899' }) => {
  return (
    <SvgIcon
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      classes={{ root: classes.root }}
    >
      <path
        d="M9.99996 11.9375H6.24996C5.08699 11.9375 4.5055 11.9375 4.03234 12.0811C2.96701 12.4042 2.13333 13.2379 1.81016 14.3033C1.66663 14.7764 1.66663 15.3579 1.66663 16.5209M13.3333 14.0209L15 15.6875L18.3333 12.3542M12.0833 5.27087C12.0833 7.34194 10.4044 9.02087 8.33329 9.02087C6.26222 9.02087 4.58329 7.34194 4.58329 5.27087C4.58329 3.19981 6.26222 1.52087 8.33329 1.52087C10.4044 1.52087 12.0833 3.19981 12.0833 5.27087Z"
        stroke={color}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export const UserCheckIcon = withStyles(styles)(UserCheckIconComponent);
