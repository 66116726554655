import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';

import { styles } from './lazy-load.styles';
import { Loading } from '../loading';

export interface LazyLoadProps extends WithStyles<typeof styles> {
  withFallback?: boolean;
  component?: React.ComponentType<any>;
}

const LazyLoad: React.FC<LazyLoadProps> = (props) => {
  const { component: Component, classes, children, withFallback, ...otherProps } = props;

  return (
    <React.Suspense fallback={withFallback ? <Loading absolute classes={{ root: classes.root }} size={40} /> : null}>
      {Component ? <Component {...otherProps} /> : children}
    </React.Suspense>
  );
};

LazyLoad.defaultProps = {
  withFallback: true,
};

export default withStyles(styles)(LazyLoad);
