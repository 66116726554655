import { Components } from '@mui/material/styles';

import { colors } from '@shared/theme/colours';

export const initializeButtonComponents = (
  config?: Components['MuiButton']
): Components['MuiButton'] => {
  return {
    styleOverrides: {
      root: {
        borderRadius: 6,
        textTransform: 'none',
        color: 'inherit',
        fontSize: 14,
        whiteSpace: 'nowrap',

        '&.Mui-disabled': {
          color: 'inherit',
          '& .MuiButton-startIcon, & .MuiButton-endIcon, & svg': {
            color: 'inherit',
          }
        }
      },
      disabled: {},
      contained: {
        '&.Mui-disabled': {
          opacity: 0.5,
        },
      },
      containedPrimary: {
        color: `${colors.white.lightest} !important`,
        backgroundColor: `${colors.kato.navy[950]} !important`,

        '&:hover': {
          backgroundColor: colors.kato.navy[900],
          boxShadow: 'none',
        },
      },
      containedSizeSmall: {
        padding: '4px 14px',
      },
      outlined: {
        border: `1px solid ${colors.grey.lightish}`,
        backgroundColor: colors.white.lightest,

        '&:hover': {
          borderColor: colors.grey.icon,
          backgroundColor: colors.grey.lighter,
        },
      },
      sizeSmall: {
        height: 36,
      },
      outlinedSizeSmall: {
        height: 36,
      },
      startIcon: {
        marginRight: 5,
      },
      iconSizeMedium: {
        fontSize: 11,

        '&> *:first-child': {
          fontSize: 16,
        },
      },
    },
    ...config,
  };
};

