import createStyles from '@mui/styles/createStyles';

import { colors } from '@shared/theme/colours';

export const styles = () =>
  createStyles({
    root: {
      fill: 'transparent',
      color: colors.black.darker,
    },
  });
