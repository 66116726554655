import { Drawer, Link } from '@mui/material';
import { FC, useMemo, useState } from 'react';

import { useTasksDeleteMutation, useTasksShowQuery } from '@apis/tasksApi';
import { useConfig } from '@core/contexts/ConfigContext';
import { t } from '@core/i18n';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Button } from '@shared/components/button';
import { DrawerHeader } from '@shared/components/drawer-header';
import { DrawerOverlay, DrawerOverlayTheme } from '@shared/components/drawer-overlay';
import { Flex } from '@shared/components/flex';
import { Loading } from '@shared/components/loading';
import { ViewingFeedback } from '@shared/components/viewing-feedback';
import { drawerTransition } from '@shared/constants/drawer';
import ROUTES from '@shared/constants/routes';
import { ExternalLinkIcon } from '@shared/icons/external-link';
import { ViewingOutcome } from '@shared/models/viewing/viewings';
import { formatDate, getViewingDateTimeInfo } from '@shared/utils/date';
import { normalizedHTMLText } from '@shared/utils/text';

import { styles } from './ViewingsViewDrawer.styles';

export interface ViewingsViewDrawerProps extends WithStyles<typeof styles> {
  viewId: string;
  open: boolean;
  onClose: () => void;
  onEditOpen: (viewId: Id) => void;
  onFeedbackOpen: (viewId: Id) => void;
}
const ViewingsViewDrawerComponent: FC<ViewingsViewDrawerProps> = ({
  classes,
  viewId,
  open,
  onClose,
  onEditOpen,
  onFeedbackOpen,
}) => {
  const { user } = useConfig();
  const [deletingViewOverlay, setDeletingViewOverlay] = useState(false);

  const tasksShowQuery = useTasksShowQuery(viewId);

  const tasksDeleteMutation = useTasksDeleteMutation({ onSuccess: onClose });

  const disabledFields = useMemo(() => tasksShowQuery.isFetching, [tasksShowQuery.isFetching]);

  const renderDateInfo = (date: string, time: string) => {
    return (
      <>
        {date}
        <span className={classes.blockText}>({time})</span>
      </>
    );
  };

  const handleDeleteView = () => {
    if (viewId) {
      tasksDeleteMutation.mutate(viewId);
    }
  };

  const renderDetailLink = (to: string, id: Id, text: string) => {
    return (
      (<Link
        key={id}
        href={to}
        target="_blank"
        classes={{ root: classes.linkContent }}
        underline="hover">
        {text}
        <ExternalLinkIcon classes={{ root: classes.linkIcon }} />
      </Link>)
    );
  };
  const relatedDetailsConfig = useMemo(
    () => [
      {
        title: t('disposals'),
        content: (
          <Flex direction="column" classes={{ root: classes.linkContainer }}>
            {tasksShowQuery.data?.disposals?.length
              ? tasksShowQuery.data?.disposals.map((disposal) => {
                  return renderDetailLink(
                    `/disposals/${disposal.id}/summary`,
                    disposal.id,
                    `${disposal.name || t('no_name')} `
                  );
                })
              : '-'}
          </Flex>
        ),
      },
      {
        title: t('requirements'),
        content: (
          <Flex direction="column" classes={{ root: classes.linkContainer }}>
            {tasksShowQuery.data?.requirements?.length
              ? tasksShowQuery.data?.requirements.map((requirement) => {
                  return renderDetailLink(
                    `/requirements/${requirement.id}/manage`,
                    requirement.id,
                    `${requirement.title || t('no_title')} `
                  );
                })
              : '-'}
          </Flex>
        ),
      },
      {
        title: t('contact'),
        content: (
          <Flex direction="column" classes={{ root: classes.linkContainer }}>
            {tasksShowQuery.data?.contacts?.length
              ? tasksShowQuery.data?.contacts.map((contact) => {
                  return renderDetailLink(
                    `${ROUTES.organisation.crm.root}${ROUTES.organisation.crm.contacts}?details=${contact?.id}`,
                    contact.id,
                    `${contact.forename} ${contact.surname} `
                  );
                })
              : '-'}
          </Flex>
        ),
        className: !user.societies.length ? classes.detailItemLast : '',
      },
    ],
    [tasksShowQuery.data, user]
  );

  const feedbackContent = useMemo(() => {
    if (tasksShowQuery.data?.outcome) {
      return (
        <ViewingFeedback
          classes={{ feedbackContainer: classes.feedbackContainer }}
          status={tasksShowQuery.data.outcome}
          feedbackText={tasksShowQuery.data?.comments[0]?.comments ?? ''}
        />
      );
    }

    if (tasksShowQuery.data?.awaiting_feedback) {
      return (
        <ViewingFeedback
          classes={{ feedbackContainer: classes.feedbackContainer }}
          status={ViewingOutcome.Pending}
          feedbackText={''}
        />
      );
    }

    return;
  }, [tasksShowQuery.data]);

  const buttonsContent = useMemo(
    () => (
      <>
        <Flex wrap="nowrap" classes={{ root: classes.footerBtnContainer }}>
          <Button
            text={t(tasksShowQuery.data?.awaiting_feedback ? 'cancel_viewing' : 'delete_viewing')}
            variant="outlined"
            disabled={disabledFields}
            loading={disabledFields}
            onClick={() => setDeletingViewOverlay(true)} />
        </Flex>
        {tasksShowQuery.data?.awaiting_feedback && (
          <Flex wrap="nowrap" classes={{ root: classes.footerBtnContainer }}>
            <Button
              text={t('edit_or_reschedule')}
              variant="outlined"
              onClick={() => onEditOpen(viewId)}
              disabled={disabledFields}
              loading={disabledFields} />
            <Button disabled={disabledFields} onClick={() => onFeedbackOpen(viewId)} text={t('log_feedback')} />
          </Flex>
        )}
      </>
    ),
    [disabledFields, viewId, tasksShowQuery.data]
  );

  const content = useMemo(() => {
    if (tasksShowQuery.isLoading) {
      return <Loading />;
    }

    if (deletingViewOverlay) {
      return (
        <DrawerOverlay
          buttons={[
            {
              color: 'default',
              disabled: tasksDeleteMutation.isLoading,
              text: t('back'),
              variant: 'outlined',
              onClick: () => setDeletingViewOverlay(false),
            },
            {
              className: classes.deleteBtn,
              loading: tasksDeleteMutation.isLoading,
              text: t(tasksShowQuery.data?.awaiting_feedback ? 'cancel_viewing' : 'delete_viewing'),
              onClick: handleDeleteView,
            },
          ]}
          classes={{ root: classes.drawerOverlay }}
          heading={t('delete_view_question')}
          message={t('are_you_sure_you_want_to_delete_this_view_question')}
          theme={DrawerOverlayTheme.error}
        />
      );
    }
    return (
      <>
        <div className={classes.content}>
          <Flex direction="column" classes={{ root: classes.contentItems }}>
            {feedbackContent}
            <Flex direction="column" classes={{ root: classes.block }}>
              <span className={classes.blockTitle}>{t('viewing_details')}</span>
              <Flex direction="column" classes={{ root: classes.blockInfo }}>
                <Flex direction="column">
                  <span className={classes.blockSubtitle}>{t('title')}</span>
                  <span className={classes.blockText}>{tasksShowQuery.data?.title}</span>
                </Flex>

                <Flex direction="column">
                  <span className={classes.blockSubtitle}>{t('when')}</span>
                  <span className={classes.blockText}>
                    {tasksShowQuery.data &&
                      renderDateInfo(
                        formatDate(tasksShowQuery.data.from, 'dddd, MMMM Do YYYY'),
                        getViewingDateTimeInfo(
                          tasksShowQuery.data?.from,
                          tasksShowQuery.data?.to,
                          tasksShowQuery.data?.all_day
                        )
                      )}
                  </span>
                </Flex>

                <Flex direction="column">
                  <span className={classes.blockSubtitle}>{t('assigned_to')}</span>
                  <span className={classes.blockText}>{tasksShowQuery.data?.assignee_string}</span>
                </Flex>
              </Flex>
            </Flex>

            <Flex direction="column" classes={{ root: classes.block }}>
              <Flex direction="column" classes={{ root: classes.blockInfo }}>
                {relatedDetailsConfig.map((el) => (
                  <Flex direction="column" key={el.title}>
                    <span className={classes.blockSubtitle}>{el.title}</span>
                    <span className={classes.blockText}>{el.content}</span>
                  </Flex>
                ))}
              </Flex>
            </Flex>
            <Flex direction="column" classes={{ root: classes.block }}>
              <span className={classes.blockTitle}>{t('notes')}</span>
              <span
                className={classes.notesText}
                dangerouslySetInnerHTML={{
                  __html: normalizedHTMLText(tasksShowQuery.data?.notes) || t('no_further_details_about_this_task'),
                }}
              />
            </Flex>
          </Flex>
        </div>
        <div className={classes.footer}>{buttonsContent}</div>
      </>
    );
  }, [
    tasksShowQuery.data,
    tasksShowQuery.isLoading,
    deletingViewOverlay,
    relatedDetailsConfig,
    disabledFields,
    tasksDeleteMutation.isLoading,
  ]);
  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="right"
      transitionDuration={drawerTransition}
      classes={{ paper: classes.root }}
    >
      {!deletingViewOverlay && (
        <DrawerHeader
          onClose={onClose}
          header={t('viewing')}
          classes={{
            header: classes.header,
            headerBtn: classes.headerCloseBtn,
            headerText: classes.headerText,
          }}
        />
      )}
      {content}
    </Drawer>
  );
};
export const ViewingsViewDrawer = withStyles(styles)(ViewingsViewDrawerComponent);
