import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

import { colors } from '@shared/theme/colours';

export default ({ spacing }: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    label: {
      margin: spacing(5, 0, 2, 0),
    },
    formControl: {
      marginBottom: spacing(2),
    },
    formControlLabel: {
      fontSize: 14,
      paddingTop: spacing(1),
    },
    formControlRadio: {},
    formControlRadioError: {
      borderColor: colors.red.darkest,
    },
    formControlLabelDisabled: {},
    radios: {
      alignItems: 'flex-start',
    },
    radiosRow: {
      flexDirection: 'row',
    },
    radiosColumn: {
      flexDirection: 'column',
    },
    helperText: {
      paddingTop: spacing(1),
    },
    radioWrapper: {},
    additionalContent: {},
  });
