import { CircularProgress, Button as MuiButton, ButtonProps as MuiButtonProps, TooltipProps } from '@mui/material';
import cx from 'classnames';
import { HTMLProps } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Tooltip } from '@shared/components/tooltip';
import { ButtonColour, ButtonSize } from '@shared/types/common/button';

import { styles } from './button.styles';

export interface ButtonProps extends Omit<MuiButtonProps, 'classes' | 'color' | 'size'>, WithStyles<typeof styles> {
  colour?: ButtonColour;
  component?: React.ElementType;
  active?: boolean;
  disabled?: boolean;
  hovered?: boolean;
  loading?: boolean;
  size?: ButtonSize;
  text?: React.ReactNode;
  tooltip?: string;
  tooltipPlacement?: TooltipProps['placement'];
  target?: HTMLProps<HTMLAnchorElement>['target'];
}

const ButtonComponent: React.FC<ButtonProps> = ({
  active = false,
  children,
  classes,
  colour = ButtonColour.secondaryColour,
  disabled = false,
  hovered = false,
  loading = false,
  size = ButtonSize.medium,
  text,
  tooltip,
  tooltipPlacement = 'left',
  ...buttonProps
}) => {
  const spinner = loading ? (
    <CircularProgress
      color={colour === ButtonColour.primary ? 'secondary' : 'primary'}
      size={size === ButtonSize.extraSmall ? 12 : 15}
      style={{ marginLeft: 8 }}
    />
  ) : null;

  const content = (
    <MuiButton
      {...buttonProps}
      disabled={disabled}
      disableRipple
      classes={{
        root: cx(classes.root, classes[colour], classes[size], {
          [classes.buttonDisabled]: disabled,
          [classes.buttonActive]: active,
          [classes.buttonHovered]: hovered,
          [classes.buttonLoading]: loading,
        }),
        startIcon: classes.buttonStartIcon,
        text: classes.buttonLabel,
        endIcon: classes.buttonEndIcon,
      }}
    >
      {text || children} {spinner}
    </MuiButton>
  );

  if (tooltip) {
    return (
      <Tooltip placement={tooltipPlacement || undefined} title={tooltip}>
        {content}
      </Tooltip>
    );
  }

  return content;
};

export const Button = withStyles(styles)(ButtonComponent);
