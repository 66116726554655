import createStyles from '@mui/styles/createStyles';

export const styles = () =>
  createStyles({
    root: {
      textDecoration: 'none !important',
      '&:hover': {
        textDecoration: 'none !important',
      }
    },
    rootExternal: {
      textDecoration: 'none !important',
      '&:hover': {
        textDecoration: 'none !important',
      }
    },
    rootInternal: {
      textDecoration: 'none !important',
      '&:hover': {
        textDecoration: 'none !important',
      }
    },
  });
