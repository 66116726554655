import { alpha, Components } from '@mui/material/styles';

import { colors } from '@shared/theme/colours';

export const initializeBackdropComponents = (
  config?: Components['MuiBackdrop']
): Components['MuiBackdrop'] => {
  return {
    styleOverrides: {
      root: {
        backgroundColor: alpha(colors.black.darker, 0.4),
      },
    },
    ...config,
  };
};
