import { SvgIcon } from '@mui/material';
import { FC } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';

import { styles } from './Close.styles';

export interface CloseIconProps extends WithStyles<typeof styles> {
  color?: string;
}

const CloseIconComponent: FC<CloseIconProps> = ({ classes, color = '#7E8899' }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      classes={{ root: classes.root }}
    >
      <path
        d="M12.5 4.80566L4.5 12.8057M4.5 4.80566L12.5 12.8057"
        stroke={color}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export const CloseIcon = withStyles(styles)(CloseIconComponent);
