import { alpha, Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

import { colors } from '@shared/theme/colours';

const linkHorizSpacing = 2;

const lineWidth = 1;
const lineDotSize = 5;
const lineDotHorizSpacing = 1.5;

export const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    toggleButton: {
      borderRadius: 6,
      color: alpha(colors.white.lightest, 0.65),
      cursor: 'pointer',
      gap: 8,
      height: 40,
      padding: spacing(2.5, 2),
      transition: 'background-color 0.4s ease,stroke 0.4s, color 0.4s ease',

      '& svg path': {
        stroke: 'currentColor',
      },

      '&:hover': {
        backgroundColor: alpha(colors.white.lightest, 0.15),
        color: colors.white.lightest,
        cursor: 'pointer',
        stroke: colors.white.lightest,

        '& $toggleStartIcon': {
          '& svg': {
            color: colors.white.lightest,
          },
        },

        '& $toggleIcon': {
          '& svg': {
            color: colors.white.lightest,
          },
        },
      },

      '&$toggleButtonActive': {
        color: colors.white.lightest,
        cursor: 'default',

        '& svg path': {
          stroke: colors.white.lightest,
        },
      },
    },
    toggleStartIcon: {
      display: 'inherit',
      fontSize: 18,

      '& i': {
        blockSize: '20px',
      },

      '& svg': {
        fontSize: 'inherit',
      },

      '& svg ': {
        stroke: alpha(colors.white.lightest, 0.65),

        '& path': {
          stroke: 'inherit',
        },

        '& g path': {
          stroke: 'inherit',
        },
      },
    },
    toggleText: {
      flex: '1 1 auto',
      fontSize: 14,
      fontWeight: 500,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      userSelect: 'none',
    },
    toggleIcon: {
      flex: '0 0 auto',
      fontSize: 10,
      transition: 'transform 0.3s ease',

      '& path': {
        stroke: alpha(colors.white.lightest, 0.65),
      },

      '&$toggleIconCollapsed': {
        transform: 'rotate(180deg)',
      },
    },
    link: {
      alignItems: 'center',
      borderRadius: 6,
      color: alpha(colors.white.lightest, 0.65),
      display: 'flex',
      flexWrap: 'nowrap',
      gap: 8,
      height: 40,
      maxWidth: '100%',
      padding: spacing(2.5, linkHorizSpacing),
      position: 'relative',
      transition: 'background-color 0.4s ease, color 0.4s ease',
      userSelect: 'none',

      '&:hover': {
        backgroundColor: alpha(colors.white.lightest, 0.15),
        color: colors.white.lightest,

        '& $linkDot': {
          backgroundColor: colors.white.lightest,
        },
      },

      '&:first-of-type': {
        '& $linkDotContainer': {
          '&::after': {
            top: 20,
          },
        },
      },
      '&:last-of-type': {
        '& $linkDotContainer': {
          '&::after': {
            bottom: 20,
          },
        },
      },

      '&$linkActive': {
        backgroundColor: alpha(colors.white.lightest, 0.15),
        color: colors.white.lightest,

        '& $linkDot': {
          backgroundColor: palette.primarySpecial.main,
          boxShadow: `0px 0px 0px 4px ${alpha(palette.primarySpecial.main, 0.3)}`,
        },

        '&:hover': {
          '& $linkDot': {
            backgroundColor: palette.primarySpecial.main,
          },
        },
      },
    },
    linkText: {
      flex: '1 1 auto',
      fontSize: 14,
      fontWeight: 500,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    linkDotContainer: {
      height: lineDotSize,
      margin: `0 ${spacing(lineDotHorizSpacing)} 0 ${spacing(lineDotHorizSpacing) + lineWidth}px`,
      minWidth: lineDotSize,
      width: lineDotSize,

      '&::after': {
        background: '#565470', //TODO replace after creating new palette
        bottom: 0,
        content: '""',
        left: spacing(linkHorizSpacing) + spacing(lineDotHorizSpacing) + lineWidth + (lineDotSize - lineWidth) / 2,
        position: 'absolute',
        top: 0,
        width: lineWidth,
        zIndex: 1,
      },
    },
    linkDot: {
      backgroundColor: '#565470', //TODO replace after creating new palette
      borderRadius: '50%',
      height: lineDotSize,
      position: 'relative',
      transition: 'background 0.4s ease',
      width: lineDotSize,
      zIndex: 2,
    },
    linkBadge: {
      flex: '0 0 auto',
      padding: spacing(1, 1.5),
      fontSize: 10,
    },
    badgeIcon: {
      blockSize: '13px',
      fontSize: 12,
    },

    // Utility classes - DO NOT STYLE
    linkActive: {},
    toggleButtonActive: {},
    toggleIconCollapsed: {},
  });
