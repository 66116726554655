import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

import { colors } from '@shared/theme/colours';

import { BADGE_SIZE, BADGE_THEME } from './Badge';

export const styles = ({ spacing, shape }: Theme) =>
  createStyles({
    root: {},
    badge: {
      alignItems: 'center',
      gap: 4,
      borderRadius: shape.borderRadius,
      display: 'inline-flex',
      fontWeight: 600,
      letterSpacing: 0.5,
      lineHeight: '1',
    },
    counterRoot: {
      height: 17,
      marginLeft: spacing(1),
      minWidth: 17,
      width: 17,
    },
    counterCount: {
      fontSize: 11,
    },

    [BADGE_SIZE.small]: {
      fontSize: 11,
      height: 20,
      padding: spacing(0.5, 1.5, 0),
    },
    [BADGE_SIZE.default]: {
      fontSize: 12,
      height: 22,
      padding: spacing(0.5, 2, 0),
    },
    [BADGE_SIZE.large]: {
      fontSize: 14,
      height: 24,
      padding: spacing(0.5, 2, 0.5),
    },

    [BADGE_THEME.grey]: {
      border: `1px solid ${colors.grey.lighter}`,
      backgroundColor: colors.grey.lighter,
      color: colors.grey.main,

      '& $counterRoot': {
        backgroundColor: colors.grey.main,
      },
      '& $counterCount': {
        color: colors.grey.lighter,
      },
    },
    [BADGE_THEME.green]: {
      border: `1px solid ${colors.green.lightest}`,
      backgroundColor: colors.green.lightest,
      color: '#027A48', // TODO: Change after palette updated

      '& $counterRoot': {
        backgroundColor: '#027A48', // TODO: Change after palette updated
      },
      '& $counterCount': {
        color: colors.green.lightest,
      },
    },
    [BADGE_THEME.warning]: {
      border: `1px solid ${colors.orange.main}`,
      backgroundColor: colors.orange.main,
      color: colors.white.lightest,

      '& $counterRoot': {
        backgroundColor: colors.white.lightest,
      },
      '& $counterCount': {
        color: colors.orange.main,
      },
    },
    [BADGE_THEME.danger]: {
      border: `1px solid ${colors.red.main}`,
      backgroundColor: colors.red.main,
      color: colors.white.lightest,

      '& $counterRoot': {
        backgroundColor: colors.white.lightest,
      },
      '& $counterCount': {
        color: colors.red.main,
      },
    },
    [BADGE_THEME.purple]: {
      border: `1px solid ${colors.purple.light}`,
      backgroundColor: colors.purple.light,
      color: colors.purple.main,

      '& $counterRoot': {
        backgroundColor: colors.purple.main,
      },
      '& $counterCount': {
        color: colors.purple.light,
      },
    },
    [BADGE_THEME.blue]: {
      border: `1px solid ${colors.blue.light}`,
      backgroundColor: colors.blue.light,
      color: colors.blue.darkish,

      '& $counterRoot': {
        backgroundColor: colors.blue.darkish,
      },
      '& $counterCount': {
        color: colors.blue.light,
      },
    },
    [BADGE_THEME.empty]: {
      border: `1px solid transparent`,
      backgroundColor: 'transparent',
      color: colors.grey.main,

      '& $counterRoot': {
        backgroundColor: colors.grey.main,
      },
      '& $counterCount': {
        color: colors.white.lightest,
      },
    },
    [BADGE_THEME.deepBlue]: {
      border: `1px solid transparent`,
      backgroundColor: '#EBEEFF', // TODO: Change after palette updated
      color: '#263CD1', // TODO: Change after palette updated

      '& $counterRoot': {
        backgroundColor: '#263CD1', // TODO: Change after palette updated
      },
      '& $counterCount': {
        color: '#EBEEFF', // TODO: Change after palette updated
      },
    },
    [BADGE_THEME.orange]: {
      border: `1px solid transparent`,
      backgroundColor: '#FFF5EB', // TODO: Change after palette updated
      color: '#B54708', // TODO: Change after palette updated

      '& $counterRoot': {
        backgroundColor: '#B54708', // TODO: Change after palette updated
      },
      '& $counterCount': {
        color: '#FFF5EB', // TODO: Change after palette updated
      },
    },
  });
