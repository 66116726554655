import { Components } from '@mui/material/styles';

import { colors } from '@shared/theme/colours';

export const initializeListItemComponents = (
  config?: Components['MuiListItem']
): Components['MuiListItem'] => {
  return {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          backgroundColor: colors.blue.lightest,
          '&:hover': {
            backgroundColor: colors.blue.lightest,
          },
        },
        '&.MuiListItemButton-root:hover': {
          backgroundColor: colors.blue.lightest,
        },
      },
    },
    ...config,
  };
};
