import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

import { colors } from '@shared/theme/colours';
import { InputSize } from '@shared/types/common/input';

export const styles = ({ spacing }: Theme) => {
  return createStyles({
    root: {},
    rootFullWidth: {
      width: '100%',
    },
    input: {
      backgroundColor: colors.white.lightest,

      '&.Mui-disabled': {
        backgroundColor: '#EDECF1', // TODO: Change after palette updated
        boxShadow: 'none',
        color: '#7E8899', // TODO: Change after palette updated
        cursor: 'not-allowed',
        pointerEvents: 'none',
        userSelect: 'none',

        '&.Mui-disabled > input': {
          color: 'currentColor',
        },

        '& > .MuiOutlinedInput-notchedOutline': {
          borderColor: '#EDECF1', // TODO: Change after palette updated
        },
      },

      '&:hover': {
        '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '#9EA6B3', // TODO: Change after palette updated
        },
      },

      '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderWidth: '1px !important',
        boxShadow: '0px 0px 0px 4px rgba(50, 77, 252, 0.1);',
        transition: 'border-color 0.3s ease, box-shadow 0.3s ease',
      },

      '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
        boxShadow: '0px 0px 0px 4px rgb(217 45 32 / 8%)',
      },

      // '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      //   borderColor: `${colors.grey.main} !important`,
      // },
    },
    labelRoot: {
      color: '#101828',
      fontSize: 14,
      fontWeight: 500,
      marginBottom: spacing(2),
    },
    label: {},
    labelError: {
      color: '#C52810 !important', //TODO replace after new palette creating
    },
    helperText: {
      paddingTop: spacing(1),
    },

    // Sizes
    [InputSize.small]: {
      height: 32,
    },
    [InputSize.mediumLegacy]: {
      height: 36,
    },
    [InputSize.medium]: {
      height: 40,
    },
    [InputSize.large]: {
      height: 48,
    },
  });
};
