import createStyles from '@mui/styles/createStyles';

const styles = () =>
  createStyles({
    root: {
      fill: 'transparent',
    },
  });

export default styles;
