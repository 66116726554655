import { Components } from '@mui/material/styles';

export const initializeMenuComponents = (config?: Components['MuiMenu']): Components['MuiMenu'] => {
  return {
    styleOverrides: {
      paper: {
        maxHeight: 350,
      },
    },
    ...config,
  };
};
