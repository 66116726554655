import { Components } from '@mui/material/styles';

import { colors } from '@shared/theme/colours';

export const initializeTooltipComponents = (
  config?: Components['MuiTooltip']
): Components['MuiTooltip'] => {
  return {
    styleOverrides: {
      tooltip: {
        color: colors.white.lightest,
        backgroundColor: colors.black.darkest,
      },
    },
    ...config,
  };
};
