import { Link } from '@mui/material';
import { FC } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { ConfigCore } from '@shared/models/config';

import { styles } from './ComptonNavigationLogo.styles';
import { LogoImage } from '../logo-image';

export interface ComptonNavigationLogoProps extends WithStyles<typeof styles> {
  appLogos: ConfigCore['appLogos'];
  katoUrl: string;
  url: string;
}

const ComptonNavigationLogoComponent: FC<ComptonNavigationLogoProps> = ({ classes, appLogos, katoUrl, url }) => {
  return (
    (<span className={classes.root}>
      <LogoImage appLogos={appLogos} classes={{ root: classes.logo }} url={url} />
      <span className={classes.poweredBy}>
        Powered by
        <Link
          href={katoUrl}
          classes={{ root: classes.poweredByLink }}
          target="_blank"
          underline="hover">
          Kato
        </Link>
      </span>
    </span>)
  );
};

export const ComptonNavigationLogo = withStyles(styles)(ComptonNavigationLogoComponent);
