import { IconButton } from '@mui/material';
import { FC } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Flex } from '@shared/components/flex';
import { CloseIcon } from '@shared/icons/close';
import { InfoIcon } from '@shared/icons/info';

import { styles } from './Toast.styles';

export interface ToastProps extends WithStyles<typeof styles> {
  title: string;
  onClose: () => void;
}

const ToastComponent: FC<ToastProps> = ({ children, classes, title, onClose }) => {
  return (
    (<Flex autoWidth={false} className={classes.root} wrap="nowrap">
      <Flex autoWidth={false} classes={{ root: classes.iconContainer }}>
        <InfoIcon classes={{ root: classes.icon }} />
      </Flex>
      <Flex autoWidth={false} direction="column" classes={{ root: classes.content }}>
        <Flex className={classes.heading} wrap="nowrap">
          <span className={classes.headingTitle}>{title}</span>

          <IconButton onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
        </Flex>
        {children}
      </Flex>
    </Flex>)
  );
};

export const Toast = withStyles(styles)(ToastComponent);
