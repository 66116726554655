import { SvgIcon } from '@mui/material';
import { FC } from 'react';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';
import { colors } from '@shared/theme/colours';

import { styles } from './BarChart.styles';

export interface BarChartIconProps extends WithStyles<typeof styles> {
  color?: string;
}

const BarChartIconComponent: FC<BarChartIconProps> = ({ classes, color = colors.white.lightest }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      classes={{ root: classes.root }}
    >
      <path
        d="M6.5 4.85421H2.83333C2.36662 4.85421 2.13327 4.85421 1.95501 4.94504C1.79821 5.02493 1.67072 5.15241 1.59083 5.30922C1.5 5.48748 1.5 5.72083 1.5 6.18754V15.1875C1.5 15.6543 1.5 15.8876 1.59083 16.0659C1.67072 16.2227 1.79821 16.3502 1.95501 16.43C2.13327 16.5209 2.36662 16.5209 2.83333 16.5209H6.5M6.5 16.5209H11.5M6.5 16.5209L6.5 2.85421C6.5 2.3875 6.5 2.15414 6.59083 1.97588C6.67072 1.81908 6.79821 1.6916 6.95501 1.6117C7.13327 1.52087 7.36662 1.52087 7.83333 1.52087L10.1667 1.52087C10.6334 1.52087 10.8667 1.52087 11.045 1.6117C11.2018 1.6916 11.3293 1.81908 11.4092 1.97588C11.5 2.15414 11.5 2.3875 11.5 2.85421V16.5209M11.5 8.18754H15.1667C15.6334 8.18754 15.8667 8.18754 16.045 8.27837C16.2018 8.35826 16.3293 8.48575 16.4092 8.64255C16.5 8.82081 16.5 9.05416 16.5 9.52087V15.1875C16.5 15.6542 16.5 15.8876 16.4092 16.0659C16.3293 16.2227 16.2018 16.3502 16.045 16.43C15.8667 16.5209 15.6334 16.5209 15.1667 16.5209H11.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export const BarChartIcon = withStyles(styles)(BarChartIconComponent);
