import { FormControl, MenuItem, Select as MuiSelect, SelectProps as MuiSelectProps } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';
import cx from 'classnames';
import { ChangeEvent, FC, ReactNode, useMemo, useState } from 'react';

import { t } from '@core/i18n';
import { Ellipsis } from '@shared/components/ellipsis';
import { FieldHelperText } from '@shared/components/field-helper-text';
import { Flex } from '@shared/components/flex';
import { Option, Value } from '@shared/components/select/Select.types';
import { TextField } from '@shared/components/text-field';

import { styles } from './GroupSelect.styles';

// **************************************************
// * !! DO NOT USE COMPONENT DIRECTLY            !! *
// * !! USE SingleGroupSelect / MultiGroupSelect !! *
// **************************************************

export const filterGroupedOptionsBySearch = (groups: GroupedOption[], search: string) => {
  return groups
    .map(({ options, ...otherProps }) => {
      return {
        ...otherProps,
        options: options.filter((option) => option.label.toLowerCase().includes(search.toLowerCase())),
      };
    })
    .filter(({ options }) => options.length);
};

// **************************************************
// * !! DO NOT USE COMPONENT DIRECTLY            !! *
// * !! USE SingleGroupSelect / MultiGroupSelect !! *
// **************************************************

export interface GroupedOption {
  label: string;
  options: Option[];
}

export type SingleGroupSelectOnChange = (value: Id, option?: Option) => void;

export type MultiGroupSelectOnChange = (value: Id[], option?: Option) => void;

export type GroupSelectOnChange = (value: Id | Id[], option?: Option) => void;

// **************************************************
// * !! DO NOT USE COMPONENT DIRECTLY            !! *
// * !! USE SingleGroupSelect / MultiGroupSelect !! *
// **************************************************

export interface GroupSelectProps extends Omit<MuiSelectProps, 'classes' | 'onChange'>, WithStyles<typeof styles> {
  action?: ReactNode;
  errorText?: string;
  groupOptions: GroupedOption[];
  infoText?: string;
  label?: ReactNode | string;
  value: Option['id'][];
  placeholder?: string;
  onChange: GroupSelectOnChange;
}

// **************************************************
// * !! DO NOT USE COMPONENT DIRECTLY            !! *
// * !! USE SingleGroupSelect / MultiGroupSelect !! *
// **************************************************

const GroupSelectComponent: FC<GroupSelectProps> = ({
  classes,
  label,
  groupOptions,
  onChange,
  placeholder,
  multiple = true,
  value,
  errorText,
  infoText = '',
  renderValue,
  action,
  ...otherProps
}) => {
  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);
  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = (option: Option) => {
    if (multiple) {
      if (valuesSet.has(option.id)) {
        onChange(
          value.filter((el) => el !== option.id),
          option
        );
      } else {
        onChange([...value, option.id], option);
      }
    } else {
      handleClose();
      onChange(option.id, option);
      return;
    }
  };

  const options = useMemo(() => {
    const initial: Option[] = [];

    return groupOptions.reduce((acc, el) => [...acc, ...el.options], initial);
  }, [groupOptions]);

  const valuesSet = useMemo(() => {
    if (Array.isArray(value)) {
      return new Set(value);
    }

    return new Set([value]);
  }, [value]);

  const getOption = (id: unknown) => {
    return options.find((option) => option.id == id) as Option;
  };

  const renderSelectorValue = (v: unknown) => {
    if (renderValue && v) {
      return renderValue(v as Value);
    }

    const label = Array.isArray(v) ? v.map((id) => getOption(id)?.label).join(', ') : getOption(v)?.label;

    if (!value || (Array.isArray(value) && value.length === 0)) {
      return (
        <Ellipsis
          withTooltip={false}
          maxWidth="100%"
          text={placeholder}
          classes={{ root: cx(classes.placeholder, { [classes.placeholderDisabled]: otherProps.disabled }) }}
        />
      );
    }

    return <div className={classes.value}>{label || placeholder}</div>;
  };

  const renderOption = (option: Option) => {
    return (
      <MenuItem
        key={option.id}
        onClick={() => handleClick(option)}
        selected={valuesSet.has(option.id)}
        classes={{ root: classes.menuItem, selected: classes.menuItemSelected }}
      >
        {option.label}
      </MenuItem>
    );
  };

  return (
    (<FormControl size="small" variant="outlined" classes={{ root: classes.root }}>
      <Flex autoWidth={false} justifyContent="space-between">
        {label && <span className={classes.label}>{label}</span>}
        {action}
      </Flex>
      <MuiSelect
        variant="standard"
        {...otherProps}
        open={open}
        onClose={handleClose}
        displayEmpty
        multiple
        defaultValue={placeholder}
        value={multiple && !Array.isArray(value) ? [] : value}
        className={cx(
          classes.select,
          { [classes.selectNotFocused]: !open },
          { [classes.selectDisabled]: otherProps.disabled }
        )}
        classes={{
          select: cx(classes.muiSelect, { [classes.muiSelectNotFocused]: !open }),
          icon: classes.muiIcon,
          outlined: classes.muiOutlined,
        }}
        renderValue={renderSelectorValue}
        IconComponent={KeyboardArrowDownIcon}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        onClick={() => !open && setOpen(true)}>
        <div className={classes.search}>
          <TextField
            fullWidth
            placeholder={t('xtextx_ellipsis', { text: t('search') })}
            value={search}
            onChange={handleSearch}
            classes={{ root: classes.searchField }}
          />
        </div>
        {filterGroupedOptionsBySearch(groupOptions, search).map(({ label, options }, idx) => {
          return (
            <div className={classes.group} key={label || idx}>
              <div className={classes.groupItem}>
                {label && <span className={classes.groupLabel}>{label}</span>}
                {options.map((option) => renderOption(option))}
              </div>
            </div>
          );
        })}
      </MuiSelect>
      <FieldHelperText
        classes={{ root: classes.helperText }}
        error={!!otherProps.error}
        errorText={errorText}
        infoText={infoText}
      />
    </FormControl>)
  );
};

// **************************************************
// * !! DO NOT USE COMPONENT DIRECTLY            !! *
// * !! USE SingleGroupSelect / MultiGroupSelect !! *
// **************************************************

export const GroupSelect = withStyles(styles)(GroupSelectComponent);
