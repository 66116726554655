import { Components } from '@mui/material/styles';

import { colors } from '@shared/theme/colours';

export const initializeChipComponents = (
  config?: Components['MuiChip']
): Components['MuiChip'] => {
  return {
    styleOverrides: {
      root: {},
      clickable: {
        backgroundColor: `${colors.white.lightest} !important`,
      },
    },
    ...config,
  };
};
