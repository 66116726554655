import createStyles from '@mui/styles/createStyles';

export default function styles() {
  return createStyles({
    root: {
      fontSize: 12,
      fill: 'transparent',
    },
  });
}
