import { Components } from '@mui/material/styles';

export const initializeTableCellComponents = (
  config?: Components['MuiTableCell']
): Components['MuiTableCell'] => {
  return {
    styleOverrides: {
      root: {
        padding: `4px 16px`,
      },
    },
    ...config,
  };
};
