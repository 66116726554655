import {
  FormLabel,
  InputAdornment,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import cx from 'classnames';
import {
  forwardRef,
  ForwardRefRenderFunction,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FieldRenderProps } from 'react-final-form';

import { FieldHelperText } from '@shared/components/field-helper-text';
import { SearchIcon } from '@shared/icons/search';
import { InputSize } from '@shared/types/common/input';

import { styles } from './TextField.styles';

type Classes = WithStyles<typeof styles>;

export interface TextFieldProps
  extends Omit<MuiTextFieldProps, 'classes' | 'margin' | 'size' | 'placeholder'>,
    Classes {
  collapse?: boolean;
  endIcon?: ReactNode;
  errorText?: string;
  infoText?: string;
  label?: ReactNode;
  placeholder?: string;
  required?: boolean;
  selectOnFocus?: boolean;
  cursorToEnd?: boolean;
  size?: InputSize;
  startIcon?: ReactNode;
  warning?: boolean;
  warningText?: string;
  withSearchIcon?: boolean;
  isValid?: (value: string) => boolean;
}

export const TextFieldAdapter = ({ input, meta, ...rest }: FieldRenderProps<string, any>) => {
  const errorMessage: string = (meta.submitFailed && meta.error) || '';

  return (
    <TextField
      {...input}
      {...rest}
      error={!!errorMessage}
      errorText={errorMessage}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => input.onChange(event.target.value)}
    />
  );
};

const TextFieldComponent: ForwardRefRenderFunction<HTMLElement, TextFieldProps> = (
  {
    autoFocus,
    className,
    classes,
    collapse = false,
    cursorToEnd = false,
    error,
    errorText,
    fullWidth,
    infoText = '',
    inputProps,
    InputProps,
    isValid,
    label,
    minRows,
    multiline,
    onBlur,
    onChange,
    placeholder,
    required,
    rows,
    selectOnFocus,
    size = InputSize.mediumLegacy,
    type,
    warning,
    warningText,
    withSearchIcon,
    startIcon,
    endIcon,
    maxRows,
    value,
    ...otherProps
  },
  ref
) => {
  const inputRef = useRef<HTMLInputElement>(null);

  // Destructure classes only relevant to our wrapper component, pass otherClasses to mui
  const {
    root: rootClass,
    inputWarning: inputWarningClass,
    rootFullWidth: rootFullWidthClass,
    labelRoot: labelRootClass,
    input: inputClass,
    label: labelClass,
    labelError: labelErrorClass,
    helperText: helperTextClass,
    large,
    medium,
    mediumLegacy,
    small,
    ...otherClasses
  } = classes;

  const [focus, setFocus] = useState(false);

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      setFocus(true);
      inputRef.current.focus();
      if (selectOnFocus) {
        inputRef.current.select();
      }

      if (cursorToEnd && value) {
        inputRef.current.setSelectionRange(inputRef.current.value.length, inputRef.current.value.length);
      }
    }
  }, [autoFocus]);

  useImperativeHandle<HTMLElement, HTMLInputElement>(ref, () => ({
    ...(inputRef.current as HTMLInputElement),
    focus: () => {
      inputRef.current?.focus();
      setFocus(true);
      if (selectOnFocus) {
        inputRef.current?.select();
      }
    },
  }));

  const startAdornment = useMemo(() => {
    if (withSearchIcon) {
      return (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      );
    }
    if (startIcon) {
      return <InputAdornment position="start">{startIcon}</InputAdornment>;
    }

    return null;
  }, [startIcon, withSearchIcon]);

  const endAdornment = useMemo(() => {
    if (endIcon) {
      return <InputAdornment position="end">{endIcon}</InputAdornment>;
    }
    return null;
  }, [endIcon]);

  return (
    <div className={cx(rootClass, { [inputWarningClass]: warning }, { [rootFullWidthClass]: fullWidth }, className)}>
      {label && (
        <FormLabel
          classes={{ root: cx(labelRootClass, labelClass), error: labelErrorClass }}
          component="legend"
          error={error}
        >
          {label}
        </FormLabel>
      )}
      <MuiTextField
        autoFocus={autoFocus}
        classes={otherClasses}
        error={error}
        fullWidth={fullWidth}
        inputProps={{ ...inputProps }}
        inputRef={inputRef}
        maxRows={maxRows}
        minRows={!collapse || focus || value ? minRows : 1}
        multiline={multiline}
        placeholder={placeholder || undefined}
        type={type}
        value={value}
        variant="outlined"
        InputProps={{
          classes: {
            root: cx(inputClass, classes[size]),
          },
          endAdornment,
          startAdornment,
          ...InputProps,
        }}
        onBlur={(e) => {
          e.persist();
          setFocus(false);

          if (!isValid || isValid(e.target.value)) {
            if (onBlur) {
              onBlur(e);
            }
          }
        }}
        onChange={(e) => {
          e.persist();

          if (!isValid || isValid(e.target.value)) {
            if (onChange) {
              onChange(e);
            }
          }
        }}
        onFocus={(e) => {
          e.persist();
          if (selectOnFocus && inputRef.current) {
            inputRef.current.select();
          }

          if (cursorToEnd && inputRef.current) {
            inputRef.current.setSelectionRange(inputRef.current.value.length, inputRef.current.value.length);
          }

          setFocus(true);
        }}
        {...otherProps}
      />
      <FieldHelperText
        classes={{ root: helperTextClass }}
        error={error}
        errorText={errorText}
        infoText={infoText}
        warning={warning}
        warningText={warningText}
      />
    </div>
  );
};

export const TextField = withStyles(styles)(forwardRef(TextFieldComponent));
