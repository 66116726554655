import { alpha, Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

import { colors } from '@shared/theme/colours';

export const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    root: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      overflow: 'hidden',
      width: '100%',
    },
    linkContainer: {
      gap: 8,
    },
    badge: {
      flex: '0 0 auto',
      padding: spacing(1, 1.5),
      fontSize: 10,
    },
    badgeIcon: {
      blockSize: '13px',
      fontSize: 12,
    },
    title: {
      display: 'inline-block',
      flex: '1 1 auto',
      fontSize: 14,
      fontWeight: 500,
      overflow: 'hidden',
      position: 'relative',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      userSelect: 'none',
    },
    externalLink: {},
    icon: {
      display: 'inline-flex',
      flex: '0 0 auto',

      '& i': {
        blockSize: 'calc(100% + 1px)',
        fontSize: `18px !important`,
      },
      '& svg': {
        blockSize: 'calc(100% + 1px)',
        fontSize: 18,
      },
      '& svg path': {
        stroke: 'inherit',

        '&:hover': {
          stroke: 'inherit',
        },
      },
    },
    link: {
      borderRadius: 6,
      color: alpha(colors.white.lightest, 0.65),
      display: 'flex',
      fontSize: 14,
      height: 40,
      justifyContent: 'space-between',
      padding: spacing(2.5, 2),
      stroke: alpha(colors.white.lightest, 0.65),
      transition: 'background-color 0.4s ease,stroke 0.4s, color 0.4s ease',
      width: '100%',

      '&:hover': {
        backgroundColor: alpha(colors.white.lightest, 0.15),
        color: colors.white.lightest,
        cursor: 'pointer',
        stroke: colors.white.lightest,
      },

      '&$linkActive': {
        backgroundColor: alpha(colors.white.lightest, 0.15),
        color: colors.white.lightest,

        '& svg path': {
          stroke: palette.primarySpecial.main,
        },

        '& $icon': {
          color: palette.primarySpecial.main,
        },
      },
    },

    // Utility classes - DO NOT STYLE
    linkActive: {},
  });
