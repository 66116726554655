import { Mixins } from '@mui/material/styles';

export type MixinsOptions = Partial<Mixins>;

export const initializeMixins = (config?: MixinsOptions): Mixins => {
  return {
    toolbar: {
      minHeight: 56,
      '@media (min-width:0px) and (orientation: landscape)': {
        minHeight: 48,
      },
      '@media (min-width:600px)': {
        minHeight: 64,
      },
    },
    ...config,
  };
};
