import { Components } from '@mui/material/styles';

export const initializeDialogComponents = (
  config?: Components['MuiDialog']
): Components['MuiDialog'] => {
  return {
    styleOverrides: {
      paper: {
        margin: 16,
      },
      paperWidthSm: {
        maxWidth: '100%',
      },
    },
    ...config,
  };
};
