import { TooltipProps } from '@mui/material';
import FormLabel from '@mui/material/FormLabel/FormLabel';
import MuiSwitch, { SwitchProps as MuiSwitchProps } from '@mui/material/Switch';
import cx from 'classnames';
import { FC, ReactNode } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Flex } from '@shared/components/flex';
import { Tooltip } from '@shared/components/tooltip';

import { styles } from './Switch.styles';

export enum SwitchType {
  default = 'default',
  square = 'square',
  round = 'round',
}

export enum SwitchSize {
  medium = 'medium',
  small = 'small',
}

export interface SwitchProps extends Omit<MuiSwitchProps, 'classes' | 'size'>, WithStyles<typeof styles> {
  label?: ReactNode;
  errorText?: string;
  error?: boolean;
  size?: SwitchSize;
  type?: SwitchType;
  tooltip?: string;
  tooltipPlacement?: TooltipProps['placement'];
}

const SwitchComponent: FC<SwitchProps> = ({
  classes,
  label,
  size,
  type,
  tooltip,
  tooltipPlacement = 'top',
  ...switchProps
}) => {
  const content = (
    <Flex
      alignItems="center"
      className={cx(classes.root, type ? classes[type] : undefined, size ? classes[size] : undefined)}
    >
      {label && (
        <FormLabel classes={{ root: classes.label }} component="legend">
          {label}
        </FormLabel>
      )}
      <MuiSwitch
        {...switchProps}
        classes={{
          root: classes.switchRoot,
          colorPrimary: classes.switchPrimary,
          thumb: classes.switchThumb,
          switchBase: classes.switchBase,
          track: classes.switchTrack,
        }}
      />
    </Flex>
  );

  if (tooltip) {
    return (
      <Tooltip
        placement={tooltipPlacement || undefined}
        title={tooltip}
        classes={{ container: classes.tooltipContainer }}
      >
        {content}
      </Tooltip>
    );
  }

  return content;
};

export const Switch = withStyles(styles)(SwitchComponent);
