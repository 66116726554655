import { SvgIcon } from '@mui/material';
import { FC } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';

import { styles } from './Help.styles';

export interface IconProps extends WithStyles<typeof styles> {
  color?: string;
}

const HelpIconComponent: FC<IconProps> = ({ classes, color = '#7E8899' }) => {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      classes={{ root: classes.root }}
    >
      <path
        d="M7.61356 7.61359L4.10745 4.10748M4.10745 15.8926L7.63998 12.3601M12.3842 12.3865L15.8903 15.8926M15.8903 4.10748L12.3573 7.64054M18.3333 10C18.3333 14.6024 14.6024 18.3334 10 18.3334C5.39763 18.3334 1.66667 14.6024 1.66667 10C1.66667 5.39765 5.39763 1.66669 10 1.66669C14.6024 1.66669 18.3333 5.39765 18.3333 10ZM13.3333 10C13.3333 11.841 11.841 13.3334 10 13.3334C8.15906 13.3334 6.66667 11.841 6.66667 10C6.66667 8.15907 8.15906 6.66669 10 6.66669C11.841 6.66669 13.3333 8.15907 13.3333 10Z"
        stroke={color}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export const HelpIcon = withStyles(styles)(HelpIconComponent);
