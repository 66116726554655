import { Drawer } from '@mui/material';
import { ChangeEvent, FC, useMemo, useState } from 'react';

import { useTasksLeaveFeedbackMutation, useTasksShowQuery } from '@apis/tasksApi';
import { TaskShowItem, TasksLeaveFeedbackPayload } from '@apis/tasksApi.types';
import { useOptions } from '@core/contexts/OptionsContext';
import { t } from '@core/i18n';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Button } from '@shared/components/button';
import { DrawerHeader } from '@shared/components/drawer-header';
import { Flex } from '@shared/components/flex';
import { Loading } from '@shared/components/loading';
import { RadioGroup, RadioGroupSerializerType } from '@shared/components/radio-group';
import { SingleSelectOnChange } from '@shared/components/select/Select.types';
import { SingleSelect } from '@shared/components/select/single-select';
import { TextField } from '@shared/components/text-field';
import { drawerTransition } from '@shared/constants/drawer';
import { ViewingOutcome } from '@shared/models/viewing/viewings';

import { styles } from './ViewingsFeedbackDrawer.styles';

export interface ViewingsFeedbackState {
  feedback: ViewingOutcome;
  notes: string;
  discountedReason: string;
}

type ViewingsFeedbackStateKey = keyof ViewingsFeedbackState;

const initialState: ViewingsFeedbackState = {
  feedback: ViewingOutcome.Neutral,
  notes: '',
  discountedReason: '',
};

export interface ViewingsFeedbackDrawerProps extends WithStyles<typeof styles> {
  viewId: string;
  open: boolean;
  onClose: () => void;
  onDetailsOpen: (view: TaskShowItem) => void;
}

const ViewingsFeedbackDrawerComponent: FC<ViewingsFeedbackDrawerProps> = ({
  classes,
  viewId,
  open,
  onClose,
  onDetailsOpen,
}) => {
  const [state, setState] = useState(initialState);

  const { discountReasonOptions, viewingFeedbackRadioOptions } = useOptions();

  const tasksShowQuery = useTasksShowQuery(viewId);

  const tasksLeaveFeedbackMutation = useTasksLeaveFeedbackMutation({ onSuccess: onClose });

  const requestData: TasksLeaveFeedbackPayload = useMemo(() => {
    return {
      id: viewId,
      body: {
        comments: state.notes,
        discounted_reason: parseInt(state.discountedReason),
        outcome: state.feedback,
        set_as_discounted: !!state.discountedReason,
      },
    };
  }, [viewId, state]);

  const handleDetailsOpen = () => {
    onClose();
    if (tasksShowQuery.data) {
      onDetailsOpen(tasksShowQuery.data);
    }
  };

  const handleUpdate = () => {
    tasksLeaveFeedbackMutation.mutate(requestData);
  };

  const handleChangeInputs = (type: ViewingsFeedbackStateKey) => (e: ChangeEvent<HTMLInputElement>) =>
    setState((_prev) => ({ ..._prev, [type]: e.target.value }));

  const disabledFields = useMemo(
    () => tasksShowQuery.isFetching || tasksLeaveFeedbackMutation.isLoading,
    [tasksShowQuery.isFetching, tasksLeaveFeedbackMutation]
  );

  const handleRadioChange = (status: Id) => {
    setState((_prev) => ({ ..._prev, feedback: status as ViewingOutcome }));
  };

  const handleDiscountReason: SingleSelectOnChange = (e, value) => {
    setState((_prev) => ({ ..._prev, discountedReason: value.toString() }));
  };

  const discountedReasonContent = useMemo(
    () =>
      ViewingOutcome.Negative === state.feedback && (
        <Flex direction="column">
          <span className={classes.label}>{t('reason')}</span>
          <SingleSelect
            placeholder={t('xtextx_ellipsis', { text: t('please_select') })}
            options={discountReasonOptions}
            value={state.discountedReason}
            onChange={handleDiscountReason}
          />
        </Flex>
      ),
    [state]
  );

  const content = useMemo(() => {
    if (tasksShowQuery.isLoading) {
      return <Loading />;
    }

    return (<>
      <div className={classes.content}>
        <Flex direction="column" classes={{ root: classes.contentItems }}>
          <Flex direction="column" classes={{ root: classes.block }}>
            <Flex>
              <div className={classes.label}>{t('how_did_they_react_to_the_space_question')}</div>
              <div className={classes.fullRowElement}>
                <RadioGroup
                  disabled={disabledFields}
                  serializerType={RadioGroupSerializerType.string}
                  value={state.feedback}
                  options={viewingFeedbackRadioOptions}
                  onChange={handleRadioChange}
                  classes={{
                    formControl: classes.radiosFormControl,
                    radios: classes.radios,
                    formControlLabel: classes.label,
                  }}
                />
              </div>
            </Flex>
            {discountedReasonContent}
            <Flex autoWidth={false}>
              <span className={classes.label}>{t('comments')}</span>
              <TextField
                disabled={disabledFields}
                value={state.notes}
                onChange={handleChangeInputs('notes')}
                placeholder={t('comments_for_interest_schedule')}
                fullWidth
                multiline={true}
                classes={{ input: classes.notes }}
              />
            </Flex>
          </Flex>
        </Flex>
      </div>
      <div className={classes.footer}>
        <Flex wrap="nowrap" classes={{ root: classes.footerBtnContainer }}>
          <Button
            text={t('cancel')}
            variant="outlined"
            loading={disabledFields}
            onClick={handleDetailsOpen} />
        </Flex>
        <Flex wrap="nowrap" classes={{ root: classes.footerBtnContainer }}>
          <Button text={t('save')} onClick={handleUpdate} loading={disabledFields} />
        </Flex>
      </div>
    </>);
  }, [tasksShowQuery.isLoading, state, disabledFields, handleDetailsOpen, handleUpdate, discountedReasonContent]);
  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="right"
      transitionDuration={drawerTransition}
      classes={{ paper: classes.root }}
    >
      <DrawerHeader
        onClose={onClose}
        header={t('log_viewing_feedback')}
        classes={{
          header: classes.header,
          headerBtn: classes.headerCloseBtn,
          headerText: classes.headerText,
        }}
      />
      {content}
    </Drawer>
  );
};
export const ViewingsFeedbackDrawer = withStyles(styles)(ViewingsFeedbackDrawerComponent);
