import { Components } from '@mui/material/styles';

export const initializeIconButtonComponents = (
  config?: Components['MuiIconButton']
): Components['MuiIconButton'] => {
  return {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
    ...config,
  };
};
