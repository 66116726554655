import { createTheme as muiCreateTheme, DeprecatedThemeOptions, adaptV4Theme } from '@mui/material/styles';

import { initializePalette } from '@agency/theme/units/palette';
import { initializeOverrides } from '@shared/theme/overrides';
import { initializeBreakpoints } from '@shared/theme/units/breakpoints';
import { initializeComponentsProps } from '@shared/theme/units/components-props';
import { initializeDirection } from '@shared/theme/units/direction';
import { initializeMixins } from '@shared/theme/units/mixins';
import { initializeShape } from '@shared/theme/units/shape';
import { initializeSpacing } from '@shared/theme/units/spacing';
import { initializeTransitions } from '@shared/theme/units/transitions';
import { initializeTypography } from '@shared/theme/units/typography';

export function createTheme(options?: DeprecatedThemeOptions) {
  return muiCreateTheme(adaptV4Theme({
    breakpoints: initializeBreakpoints(options?.breakpoints),
    direction: initializeDirection(options?.direction),
    mixins: initializeMixins(options?.mixins),
    overrides: initializeOverrides(options?.overrides as any) as any, // TODO: this is workaround for overrides until we remove adaptV4Theme
    palette: initializePalette(options?.palette),
    props: initializeComponentsProps(options?.props),
    shape: initializeShape(options?.shape),
    spacing: initializeSpacing(options?.spacing),
    transitions: initializeTransitions(options?.transitions),
    typography: initializeTypography(options?.typography),
    }),
  );
}
