import { SvgIcon } from '@mui/material';
import { FC } from 'react';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';

import { styles } from './FileCheck.styles';

export interface FileCheckIconProps extends WithStyles<typeof styles> {
  color?: string;
}

const FileCheckIconComponent: FC<FileCheckIconProps> = ({ classes, color = '#101828' }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      classes={{ root: classes.root }}
    >
      <g clipPath="url(#clip0_2841_17283)">
        <path
          d="M16.6666 10.4377V5.68774C16.6666 4.28761 16.6666 3.58755 16.3941 3.05277C16.1544 2.58236 15.772 2.19991 15.3016 1.96023C14.7668 1.68774 14.0667 1.68774 12.6666 1.68774H7.33325C5.93312 1.68774 5.23306 1.68774 4.69828 1.96023C4.22787 2.19991 3.84542 2.58236 3.60574 3.05277C3.33325 3.58755 3.33325 4.28761 3.33325 5.68774V14.3544C3.33325 15.7545 3.33325 16.4546 3.60574 16.9894C3.84542 17.4598 4.22787 17.8422 4.69828 18.0819C5.23306 18.3544 5.9331 18.3544 7.33319 18.3544H9.99992M12.0833 15.8544L13.7499 17.5211L17.4999 13.7711"
          stroke={color}
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2841_17283">
          <rect width="20" height="20" fill="white" transform="translate(0 0.0209961)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export const FileCheckIcon = withStyles(styles)(FileCheckIconComponent);
