import { InputAdornmentProps } from '@mui/material/InputAdornment';
import { PopoverProps as PopoverPropsType } from '@mui/material/Popover';
import { TextFieldProps } from '@mui/material/TextField';
import { CancelOutlined as CancelOutlinedIcon } from '@mui/icons-material';
import { KeyboardDatePicker, KeyboardDateTimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import cx from 'classnames';
import React, { useState } from 'react';
import { InputProps as MuiInputProps } from '@material-ui/core';
import { PopoverProps as MuiPopoverProps } from '@material-ui/core';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { FieldHelperText } from '@shared/components/field-helper-text';
import { Flex } from '@shared/components/flex';
import { InputSize } from '@shared/types/common/input';
import { DATE_TIME_PICKER_FORMAT, PICKER_DATE_FORMAT } from '@shared/utils/date';

import { styles } from './DatePicker.styles';

export type MaterialDate = MaterialUiPickersDate | string | null;

export interface DatePickerProps extends WithStyles<typeof styles> {
  autoWidth?: boolean;
  dateFormat?: string;
  disabled?: boolean;
  disableFuture?: boolean;
  disablePast?: boolean;
  error?: boolean;
  errorText?: string;
  label?: string;
  minDate?: MaterialDate | Date;
  placeholder: string;
  size?: InputSize;
  type?: 'date' | 'dateAndTime';
  value: MaterialDate | Date;
  onChange: (date: MaterialDate | null, value: string | null) => void;
}

const DatePickerComponent: React.FC<DatePickerProps> = ({
  autoWidth = true,
  classes,
  dateFormat,
  disabled = false,
  disableFuture = false,
  disablePast = false,
  error,
  errorText,
  label,
  minDate,
  placeholder,
  size = InputSize.mediumLegacy,
  type = 'date',
  value,
  onChange,
}) => {
  const [open, setOpen] = useState(false);

  const className = cx(classes.picker, classes[size], { [classes.pickedDisabled]: disabled });

  // Configure date icon
  const InputAdornmentProps: Partial<InputAdornmentProps> = {
    position: 'start',
  };

  const pickerOnChange = (date: MaterialUiPickersDate | null, value?: string | null) => {
    onChange(date ?? null, value ?? null);
    setOpen(false);
  };

  // Input props
  const inputProps: TextFieldProps['inputProps'] = {};
  const InputProps: TextFieldProps['InputProps'] = {
    // Clear icon
    endAdornment: value ? (
      <CancelOutlinedIcon
        onClick={(e) => {
          e.stopPropagation();
          onChange(null, null);
        }}
        classes={{ root: classes.clearIcon }}
      />
    ) : undefined,
  };

  // Popover props
  const PopoverProps: Partial<PopoverPropsType> = {
    onClose: () => setOpen(false),
  };

  return (
    <Flex autoWidth={autoWidth} direction="column" classes={{ root: classes.root }}>
      {label && <span className={classes.label}>{label}</span>}
      {type === 'date' ? (
        <Flex direction="column" autoWidth={false}>
          <KeyboardDatePicker
            className={className}
            disabled={disabled}
            disableFuture={disableFuture}
            disablePast={disablePast}
            error={error}
            format={dateFormat || PICKER_DATE_FORMAT}
            InputAdornmentProps={InputAdornmentProps}
            InputProps={InputProps as Partial<MuiInputProps>}
            inputProps={inputProps}
            inputVariant="outlined"
            margin="none"
            minDate={minDate}
            open={open}
            placeholder={placeholder}
            PopoverProps={PopoverProps as Partial<MuiPopoverProps>}
            size="small"
            value={value}
            variant="inline"
            onChange={pickerOnChange}
            onClick={() => setOpen(true)}
          />
          <FieldHelperText error={error} errorText={errorText} />
        </Flex>
      ) : (
        <Flex direction="column" autoWidth={false}>
          <KeyboardDateTimePicker
            className={className}
            disabled={disabled}
            disableFuture={disableFuture}
            disablePast={disablePast}
            error={error}
            format={dateFormat || DATE_TIME_PICKER_FORMAT}
            hideTabs={true}
            InputAdornmentProps={InputAdornmentProps}
            InputProps={InputProps as Partial<MuiInputProps>}
            inputProps={inputProps}
            inputVariant="outlined"
            margin="none"
            minDate={minDate}
            open={open}
            placeholder={placeholder}
            size="small"
            value={value}
            variant="inline"
            onChange={pickerOnChange}
            onClick={() => setOpen(true)}
          />
          <FieldHelperText error={error} errorText={errorText} />
        </Flex>
      )}
    </Flex>
  );
};

export const DatePicker = withStyles(styles)(DatePickerComponent);
