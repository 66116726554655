import { Components } from '@mui/material/styles';

import { colors } from '@shared/theme/colours';

export const initializeTabsComponents = (
  config?: Components['MuiTabs']
): Components['MuiTabs'] => {
  return {
    styleOverrides: {
      indicator: {
        backgroundColor: colors.blue.dark,
      },
    },
    ...config,
  };
};
