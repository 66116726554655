import { Breakpoints } from '@mui/material/styles';

type BreakpointValues = Breakpoints['values'];

export const breakpointValues: BreakpointValues = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};

type BreakpointsOptions = Partial<Breakpoints>;

export const initializeBreakpoints = (config?: BreakpointsOptions): BreakpointsOptions => {
  return {
    ...config,
    values: breakpointValues,
  };
};
