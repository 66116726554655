import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

export const styles = ({ spacing, shape }: Theme) =>
  createStyles({
    field: {
      width: 185,
    },
    fieldInput: {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    noBorderRadius: {
      borderRadius: 0,
    },

    // FieldWithCard classes
    root: {},
    label: {},
    rowWrap: {},
    rowWrapReverse: {},
    disabled: {},
    cardLabel: {},
    cardSubLabel: {},
  });
