import { Components } from '@mui/material/styles';

export const initializeLinkComponents = (config?: Components['MuiLink']): Components['MuiLink'] => {
  return {
    defaultProps: {
      underline: 'none',
    },
    styleOverrides: {
      root: {
        textDecoration: 'none !important',
        '&:hover': {
          textDecoration: 'none !important',
        },
      },
    },
    ...config,
  };
};
