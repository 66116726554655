import { Shape } from '@mui/system';

export type ShapeOptions = Partial<Shape>;

export const defaultShape: ShapeOptions = {
  borderRadius: 4,
};

export const customBorderRadius = {
  primary: 30,
};

export const initializeShape = (config?: ShapeOptions): Shape => {
  return {
    borderRadius: defaultShape.borderRadius,
    ...config,
  };
};
