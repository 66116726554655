import { Components } from '@mui/material/styles';

export const initializeInputComponents = (
  config?: Components['MuiInput']
): Components['MuiInput'] => {
  return {
    styleOverrides: {
      root: {
        fontSize: 14,
      },
    },
    ...config,
  };
};
