import { Components } from '@mui/material/styles';

import { fieldBorderRadius } from '@shared/constants/field';

export const initializeOutlinedInputComponents = (
  config?: Components['MuiOutlinedInput']
): Components['MuiOutlinedInput'] => {
  return {
    styleOverrides: {
      root: {
        fontSize: 14,
        height: 36,
        borderRadius: fieldBorderRadius,
      },
      input: {
        '&.MuiInputBase-inputMarginDense': {
          paddingTop: 4,
          paddingBottom: 4,
        },
      },
      multiline: {
        height: 'auto',
      },
    },
    ...config,
  };
};
