import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

import { commonStyles } from '../Select.styles';

export function styles(theme: Theme) {
  return createStyles({
    ...commonStyles(theme),
  });
}
