import { Components } from '@mui/material/styles';

export const initializeFormControlLabelComponents = (
  config?: Components['MuiFormControlLabel']
): Components['MuiFormControlLabel'] => {
  return {
    styleOverrides: {
      root: {
        marginLeft: 0,
      },
    },
    ...config,
  };
};
