import { Components } from '@mui/material/styles';

export const initializeAccordionSummaryComponents = (
  config?: Components['MuiAccordionSummary']
): Components['MuiAccordionSummary'] => {
  return {
    styleOverrides: {
      root: {},
    },
    ...config,
  };
};
