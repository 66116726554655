import { SvgIcon } from '@mui/material';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';

import { styles } from './User.styles';

export type UserIconProps = WithStyles<typeof styles>;

const UserIconComponent: React.FC<UserIconProps> = ({ classes }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      classes={{ root: classes.root }}
    >
      <path d="M6.00008 2.13965C6.73341 2.13965 7.33341 2.73965 7.33341 3.47298C7.33341 4.20631 6.73341 4.80631 6.00008 4.80631C5.26675 4.80631 4.66675 4.20631 4.66675 3.47298C4.66675 2.73965 5.26675 2.13965 6.00008 2.13965ZM6.00008 8.80631C7.80008 8.80631 9.86675 9.66631 10.0001 10.1396H2.00008C2.15341 9.65965 4.20675 8.80631 6.00008 8.80631ZM6.00008 0.806313C4.52675 0.806313 3.33341 1.99965 3.33341 3.47298C3.33341 4.94631 4.52675 6.13965 6.00008 6.13965C7.47341 6.13965 8.66675 4.94631 8.66675 3.47298C8.66675 1.99965 7.47341 0.806313 6.00008 0.806313ZM6.00008 7.47298C4.22008 7.47298 0.666748 8.36631 0.666748 10.1396V11.473H11.3334V10.1396C11.3334 8.36631 7.78008 7.47298 6.00008 7.47298Z" />
    </SvgIcon>
  );
};

export const UserIcon = withStyles(styles)(UserIconComponent);
